import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { chain } from 'lodash'
import PropTypes from 'prop-types'

import { Modal, ModalTitle } from '@fullfabric/alma-mater'

import { getEventGroupsUnderContractQueryKeyFilter } from 'apps/Billing/constants/queryParts'
import EventGroupEventsTable from './EventGroupEventsModal/EventGroupEventsTable'

/**
 * Displays a modal with the events for an event group. Will render if the
 * `eventGroupId` is provided. Assumes the event group is available in the query
 * cache.
 *
 * Depending on the `billableType`, the modal will display applic submissions or
 * profile engagements.
 */
export default function EventGroupEventsModal({
  billableType,
  contractId,
  eventGroupId,
  onClose
}) {
  return (
    <Modal
      isOpen={!!eventGroupId}
      onClose={onClose}
      header={
        <EventGroupEventsTitle
          billableType={billableType}
          contractId={contractId}
          eventGroupId={eventGroupId}
          onClose={onClose}
        />
      }
      size='large'
    >
      {eventGroupId && (
        <EventGroupEventsTable
          billableType={billableType}
          contractId={contractId}
          eventGroupId={eventGroupId}
        />
      )}
    </Modal>
  )
}

EventGroupEventsModal.propTypes = {
  billableType: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  eventGroupId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

/**
 * Finds the Event Group in the query cache and renders the modal title with the
 * profile and - in the case of applic submissions - application names.
 */
function EventGroupEventsTitle({
  billableType,
  contractId,
  eventGroupId,
  onClose
}) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const eventGroup = useMemo(() => {
    const eventGroupsQueries = queryClient.getQueriesData(
      getEventGroupsUnderContractQueryKeyFilter(contractId)
    )

    const eventGroups = chain(eventGroupsQueries)
      .filter(([_, query]) => !!query)
      .flatMap(([_filter, query]) => query.pages.flatMap((page) => page.data))
      .value()

    return eventGroups.find((eventGroup) => eventGroup.id === eventGroupId)
  }, [contractId, eventGroupId, queryClient])

  let title
  switch (billableType) {
    case 'applic':
      title = t('{{profileName}} submissions for {{applicationName}}', {
        profileName: eventGroup.profile_name,
        applicationName: eventGroup.applic_template_name
      })
      break
    case 'engagement':
      title = t('{{profileName}} engagements', {
        profileName: eventGroup.profile_name
      })
      break
    case 'registration':
      title = t('{{profileName}} registrations', {
        profileName: eventGroup.profile_name
      })
      break
    default:
      throw new Error(`Unknown billable type: ${billableType}`)
  }

  return <ModalTitle title={title} onClose={onClose} />
}
