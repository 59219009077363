import React from 'react'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function EditorCard({ title, children }) {
  return (
    <div className={styles.container}>
      {title && (
        <Text type='h5' fontColor='text-base-darkest'>
          {title}
        </Text>
      )}
      {children}
    </div>
  )
}
