import moment from 'moment'

export const OPENED_STATES = ['extended', 'open', 'openIndefinitely']

function hasExtendedDeadline(application) {
  if (!application || !application.deadline) {
    return false
  }

  const deadlineMoment = moment(application.deadline).utc()
  const todayMoment = moment().startOf('day').utc()

  return deadlineMoment.isValid() && deadlineMoment.isSameOrAfter(todayMoment)
}

export function getTemplateState({ template, application, classOfTemplate }) {
  const isOpen = template.is_open
  const isClosed = !isOpen
  const isOpenButClassIsClosed =
    isOpen && classOfTemplate && classOfTemplate.state !== 'open'

  if (isOpenButClassIsClosed) {
    return 'closed'
  }

  if (application && hasExtendedDeadline(application)) {
    return 'extended'
  }

  if (isOpen && template.closes_at) {
    return 'open'
  }

  if (isClosed && moment(template.current_time).isBefore(template.opens_at)) {
    return 'upcoming'
  }

  if (isClosed) {
    return 'closed'
  }

  return 'openIndefinitely'
}
