import { intersection } from 'lodash'

import { useAppData } from 'shared/contexts/AppData'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

export const useMergeTags = () => {
  const { _user: user, profile_schema: profileSchema } = useAppData()
  const qrCodeEnabled = useSettingByName(
    'modules.events.features.event_qrcode.enable'
  )
  const courseJourneysEnabled = useSettingByName(
    'modules.core.submodules.institutions.features.course_journeys.enable'
  )

  const substitutionTagsApplication = {
    application_template_name: {
      value: '{{application_template_name}}',
      name: 'Application template name'
    },
    primary_product_choice: {
      value: '{{primary_programme_choice}}',
      name: 'Primary product choice'
    },
    secondary_product_choice: {
      value: '{{secondary_programme_choice}}',
      name: 'Secondary product choice'
    }
  }

  const substitutionTagsEvent = {
    event_name: { value: '{{event_name}}', name: 'Event name' },
    url: { value: '{{url}}', name: 'Event URL' },
    add_to_google_calendar: {
      value: '{{add_to_google_calendar}}',
      name: 'Add Event to Google calendar'
    },
    add_to_yahoo_calendar: {
      value: '{{add_to_yahoo_calendar}}',
      name: 'Add Event to Yahoo calendar'
    },
    add_to_outlook_calendar: {
      value: '{{add_to_outlook_calendar}}',
      name: 'Add Event to Outlook calendar'
    },
    add_to_office365: {
      value: '{{add_to_office365}}',
      name: 'Add Event to Office365'
    },
    add_to_ical: {
      value: '{{add_to_ical}}',
      name: 'Add Event to Local Calendar'
    },
    ...(qrCodeEnabled && { qrcode: { value: '{{QR_code}}', name: 'QR Code' } })
  }

  const substitutionTagsFormAndEvent = {
    event_or_form_product_choices: {
      value: '{{event_or_form_product_choices}}',
      name: 'Event or Form product choices'
    }
  }

  const substitutionTagsLifecycleWorkflow = {
    programme_name: { value: '{{programme_name}}', name: 'Programme name' },
    class_name: { value: '{{class_name}}', name: 'Class name' },
    ...(courseJourneysEnabled && {
      programme_or_subject_name: {
        value: '{{programme_name_or_subject_name}}',
        name: 'Programme or subject name'
      },
      class_or_course_name: {
        value: '{{class_name_or_course_name}}',
        name: 'Class or course name'
      }
    })
  }

  const substitutionTagsStaffCandidate = {
    first_name: {
      value: '{{candidate_first_name}}',
      name: "Candidate's First name"
    },
    last_name: {
      value: '{{candidate_last_name}}',
      name: "Candidate's Last name"
    },
    email: { value: '{{candidate_email}}', name: "Candidate's Email" },
    profile_url: {
      value: '{{candidate_profile_url}}',
      name: "Candidate's Profile URL"
    },
    application_url: {
      value: '{{candidate_application_url}}',
      name: "Candidate's Application URL"
    },
    evaluation_url: {
      value: '{{candidate_evaluation_url}}',
      name: "Candidate's Evaluation URL"
    }
  }

  const substitutionTagsEvaluationFields = {
    template_name: {
      value: '{{evaluation_template_name}}',
      name: 'Evaluation Template name'
    },
    assignee_first_name: {
      value: '{{evaluation_assignee_first_name}}',
      name: 'Evaluation Assignee’s first name'
    },
    assignee_last_name: {
      value: '{{evaluation_assignee_last_name}}',
      name: 'Evaluation Assignee’s last name'
    },
    assignee_email: {
      value: '{{evaluation_assignee_email}}',
      name: 'Evaluation Assignee Email'
    }
  }

  const substitutionTagsPaymentPlanFields = {
    payment_plan_url: {
      value: '{{payment_plan_url}}',
      name: "Plan's url"
    },
    payment_plan_name: {
      value: '{{payment_plan_name}}',
      name: "Plan's name"
    },
    payment_plan_profile_first_name: {
      value: '{{payment_plan_profile_first_name}}',
      name: "Plan's profile first name"
    },
    payment_plan_profile_last_name: {
      value: '{{payment_plan_profile_last_name}}',
      name: "Plan's profile last name"
    },
    payment_plan_profile_email: {
      value: '{{payment_plan_profile_email}}',
      name: "Plan's profile email"
    },
    payment_plan_fee_description: {
      value: '{{payment_plan_fee_description}}',
      name: 'Paid fee description'
    },
    payment_plan_fee_amount: {
      value: '{{payment_plan_fee_amount}}',
      name: 'Paid fee amount'
    },
    payment_plan_fee_currency: {
      value: '{{payment_plan_fee_currency}}',
      name: 'Paid fee currency'
    }
  }

  const substitutionTagsProfile = () => {
    const profileFields = {}

    const typesToIgnore = [
      'Schemable::Fields::Types::Programme',
      'Schemable::Fields::Types::File',
      'Schemable::Fields::Types::Matrix',
      'Schemable::Fields::Types::ExtendableMatrix'
    ]

    const fields =
      profileSchema?.sections?.map((section) => section.fields).flat() || []

    const getLabel = (field) => {
      return (
        field.locals.find((local) => local.locale === 'en-GB') ||
        field.locals[0]
      ).label
    }

    const isVisibleToUser = (field) => {
      const visibleRoles = field.visible_by || []

      if (user.roles === 0) {
        return false
      }

      const userRoles = user.roles
        .map((role) => [role.state, `${role.state}::${role.substate}`])
        .flat()

      return (
        visibleRoles.length === 0 ||
        user.is_admin ||
        visibleRoles.includes('anyone') ||
        intersection(visibleRoles, userRoles).length > 0
      )
    }

    fields
      .filter(
        (field) =>
          field && !typesToIgnore.includes(field.type) && isVisibleToUser(field)
      )
      .forEach((field) => {
        if (field.type === 'Schemable::Fields::Types::Address') {
          profileFields[field.name] = {
            name: getLabel(field),
            mergeTags: {
              [`${field.name}.line_1`]: {
                value: `{{${field.name}.line_1}}`,
                name: `${getLabel(field)} line 1`
              },
              [`${field.name}.line_2`]: {
                value: `{{${field.name}.line_2}}`,
                name: `${getLabel(field)} line 2`
              },
              [`${field.name}.line_3`]: {
                value: `{{${field.name}.line_3}}`,
                name: `${getLabel(field)} line 3`
              },
              [`${field.name}.city`]: {
                value: `{{${field.name}.city}}`,
                name: `${getLabel(field)} city`
              },
              [`${field.name}.state`]: {
                value: `{{${field.name}.state}}`,
                name: `${getLabel(field)} state`
              },
              [`${field.name}.post_code`]: {
                value: `{{${field.name}.post_code}}`,
                name: `${getLabel(field)} post code`
              },
              [`${field.name}.country`]: {
                value: `{{${field.name}.country}}`,
                name: `${getLabel(field)} country`
              }
            }
          }
        } else {
          profileFields[field.name] = {
            name: getLabel(field),
            value: `{{${field.name}}}`
          }
        }
      })

    return profileFields
  }

  return {
    applicationFields: {
      name: 'Application',
      mergeTags: substitutionTagsApplication
    },
    eventFields: { name: 'Event', mergeTags: substitutionTagsEvent },
    formAndEvent: {
      name: 'Form and Event',
      mergeTags: substitutionTagsFormAndEvent
    },
    profileFields: { name: 'Profile', mergeTags: substitutionTagsProfile() },
    lifecycleWorkflowFields: {
      name: 'Lifecycle workflow',
      mergeTags: substitutionTagsLifecycleWorkflow
    },
    candidateFields: {
      name: 'Staff emails',
      mergeTags: substitutionTagsStaffCandidate
    },
    evaluationFields: {
      name: 'Evaluation',
      mergeTags: substitutionTagsEvaluationFields
    },
    paymentPlanFields: {
      name: 'Payment plan',
      mergeTags: substitutionTagsPaymentPlanFields
    }
  }
}
