import { utils } from '@fullfabric/public-api'

export default async function makeEventGroupBillable(eventGroupId) {
  const response = await fetch(
    `/billing/api/event_groups/${eventGroupId}/billable`,
    { method: 'PUT' }
  )

  return await utils.checkResponse(response)
}
