import { utils } from '@fullfabric/public-api'

const removePaymentPlanFee = async ({ profileId, paymentPlanId, feeId }) => {
  const response = await fetch(
    `/book_keeper/api/profiles/${profileId}/plan/${paymentPlanId}/fees/${feeId}`,
    {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'DELETE'
    }
  )

  return await utils.checkResponse(response)
}

export default removePaymentPlanFee
