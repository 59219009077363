import React from 'react'

import FeedbackPopupMessage from '../FeedbackPopupMessage'

export default function SuccessPopupMessage({
  isDisplayed,
  onAfterTimeout,
  message
}) {
  return (
    <FeedbackPopupMessage
      isDisplayed={isDisplayed}
      onAfterTimeout={onAfterTimeout}
      message={message}
      type='success'
    />
  )
}
