import React from 'react'
import { useLocation } from 'react-router'

const ChooseNewPasswordPage = React.lazy(() =>
  import('apps/Authentication/pages/ChooseNewPasswordPage')
)
const InvalidStaffActivationLinkPage = React.lazy(() =>
  import('apps/Authentication/pages/InvalidStaffActivationLinkPage')
)
const LinkedinConfirmationPage = React.lazy(() =>
  import('apps/Authentication/pages/LinkedinConfirmationPage')
)
const LoginPage = React.lazy(() =>
  import('apps/Authentication/pages/LoginPage')
)
const SignupPage = React.lazy(() =>
  import('apps/Authentication/pages/SignupPage')
)

export function isInResetPasswordPage(urlHash) {
  return urlHash?.startsWith('#reset/')
}

export function isInConfirmAccountCreationPage(urlHash) {
  return urlHash?.startsWith('#confirm/')
}

export function isIn2FAPage(urlHash) {
  return urlHash?.startsWith('#2fa')
}

export function isInLinkedInConfirmationPage(urlHash) {
  return urlHash?.startsWith('#link')
}

export function isInSignUpPage(urlHash) {
  return urlHash?.startsWith('#signup')
}

export function isInInvalidLoginPage(urlHash) {
  return urlHash?.startsWith('#invalid')
}

export default function LoginRoutes({ ...routerProps }) {
  const location = useLocation()
  const urlHash = location.hash

  if (!urlHash) {
    return <LoginPage {...routerProps} />
  }

  if (isInResetPasswordPage(urlHash)) {
    const [, token] = /#reset\/(\w+)/.exec(urlHash) || []

    return (
      <ChooseNewPasswordPage
        {...routerProps}
        token={token}
        type={urlHash.includes('expired=true') ? 'expired' : 'other'}
      />
    )
  }

  if (isInConfirmAccountCreationPage(urlHash)) {
    return (
      <ChooseNewPasswordPage
        {...routerProps}
        token={urlHash.replace('#confirm/', '')}
        type='confirmation'
      />
    )
  }

  if (isInLinkedInConfirmationPage(urlHash)) {
    return <LinkedinConfirmationPage {...routerProps} />
  }

  if (isInSignUpPage(urlHash)) {
    return <SignupPage {...routerProps} />
  }

  if (isInInvalidLoginPage(urlHash)) {
    return <InvalidStaffActivationLinkPage {...routerProps} />
  }

  return (
    <LoginPage {...routerProps} isInSecondAuthStep={isIn2FAPage(urlHash)} />
  )
}
