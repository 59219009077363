import React, { useCallback, useState } from 'react'

import { omit } from 'lodash'

import {
  useCurrentUser,
  UserRoleUtils,
  useSettings
} from '@fullfabric/authorization-officer'
import useAnalytics from '@fullfabric/ff-analytics'
import { userHasAcceptedTypeOfCookie } from '@fullfabric/policies'

import { useAppData } from 'shared/contexts/AppData'

import CookiePromptWrapper from './CookiePromptWrapper'
import AppsRoutes from './Routes'

export default function AnalyticsApp() {
  const appData = useAppData()
  const user = useCurrentUser()
  const settings = useSettings()

  const {
    cookies_policy: cookiesPolicy,
    current_cookies_policy: currentCookiesPolicy
  } = appData

  const cookiesAccepted = useCallback(() => {
    const cookieType = cookiesPolicy?.cookie_types

    if (cookieType?.analytical) {
      setAllowsAnalytics(true)
    }
    if (cookieType?.advertising) {
      setAllowsAdvertising(true)
    }
  }, [cookiesPolicy?.cookie_types])

  const [allowsAdvertising, setAllowsAdvertising] = useState(
    userHasAcceptedTypeOfCookie({
      cookiePolicy: appData.cookies_policy,
      cookieType: 'advertising',
      userAcceptance: user?.cookies_acceptance
    })
  )
  const [allowsAnalytics, setAllowsAnalytics] = useState(
    userHasAcceptedTypeOfCookie({
      cookiePolicy: appData.cookies_policy,
      cookieType: 'analytical',
      userAcceptance: user?.cookies_acceptance
    })
  )

  const isStaffUser = user && UserRoleUtils.isStaff(user)
  const integrations = settings?.hash?.integrations || {}

  useAnalytics(
    {
      advertising: allowsAdvertising,
      analytics: allowsAnalytics
    },
    // only send GTM and GA integrations data if user is not staff
    isStaffUser ? omit(integrations, ['ga', 'gtm']) : integrations,
    {
      currentCookiesPolicy
    }
  )

  return (
    <CookiePromptWrapper onCookiesAccepted={cookiesAccepted}>
      <AppsRoutes />
    </CookiePromptWrapper>
  )
}
