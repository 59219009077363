import React from 'react'
import { useTranslation } from 'react-i18next'

import BorderBlock from '../Border'
import BorderRadiusBlock from '../BorderRadius'
import EditorCard from '../EditorCard'
import FieldStyleDropdown from '../FieldStyleDropdown'
import FieldStyleSize from '../FieldStyleSize'
import FieldTraitSize from '../FieldTraitSize'
import PaddingBlock from '../PaddingBlock'
import SizeBlock from '../SizeBlock'

import styles from './styles.module.scss'

export default function ButtonSettings({ editor }) {
  const { t } = useTranslation()

  return (
    <>
      <EditorCard>
        <FieldTraitSize editor={editor} label={t('Link address')} name='href' />
      </EditorCard>
      <EditorCard title={t('Style')}>
        <div className={styles.paddingFields}>
          <FieldStyleSize
            editor={editor}
            isColorPicker
            label={t('Background color')}
            name='decorations.background-color'
            prefixLabel=''
            placeholder='FFFFFF'
            inputOptions={{ disableClear: true }}
          />
        </div>
        <FieldStyleDropdown
          className={styles.paddingField}
          editor={editor}
          label={t('Align')}
          name={`typography.align`}
        />
      </EditorCard>
      <SizeBlock editor={editor} />
      <PaddingBlock editor={editor} />
      <BorderBlock editor={editor} />
      <BorderRadiusBlock editor={editor} />
    </>
  )
}
