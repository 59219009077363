import getRequiredToBePaidFees from 'apps/ContentPages/services/payments/get-required-to-be-paid-fees'
import getStateOfFee from 'apps/ContentPages/services/payments/get-state-of-fee'

export default function payableAllowsSubmission(payable) {
  if (payable._ruby_type === 'BookKeeper::PaymentPlan::Plan') {
    const isFixedFeeStructure = payable.fees_structure === 'fixed'

    if (isFixedFeeStructure) {
      return getRequiredToBePaidFees(payable).every(
        (fee) =>
          getStateOfFee(fee, { hideSecondaryFeeStatuses: true }) === 'paid'
      )
    } else {
      const feesAmountSum = payable.fees.reduce(
        (sum, item) => sum + (item.amount || 0),
        0
      )
      const planContextPriceAmount = payable.context_price_amount * 100

      return feesAmountSum === planContextPriceAmount
    }
  }
  return false
}
