import React from 'react'

import { Text } from '@fullfabric/alma-mater'

export default function PayerData({ user, externalPayerRequest }) {
  if (externalPayerRequest) {
    return (
      <>
        <Text type='f4' fontColor='text-base-darkest'>
          {externalPayerRequest.name}
        </Text>
        <Text type='f5' fontColor='text-base-dark'>
          ({externalPayerRequest.email})
        </Text>
      </>
    )
  }
  return (
    <>
      <Text type='f4' fontColor='text-base-darkest'>
        {user.full_name}
      </Text>
      <Text type='f5' fontColor='text-base-dark'>
        ({user.email})
      </Text>
    </>
  )
}
