import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle
} from '@fullfabric/alma-mater'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import api from '../api'

import styles from './new-plan-fee-modal/styles.module.scss'

function useRemovePaymentPlanFee({ paymentPlan, fee, setErrors, onClose }) {
  return useMutation(
    async () => {
      await api.removePaymentPlanFee({
        profileId: paymentPlan.profile_id,
        paymentPlanId: paymentPlan.id,
        feeId: fee?.id
      })
    },
    {
      onSuccess: () => onClose(),
      onError: (error) =>
        setErrors({ ...error?.data['book_keeper/payment_plan/fee'] })
    }
  )
}

function useCreateOrUpdatePaymentPlanFee({
  paymentPlan,
  fee,
  feeDescription,
  data,
  setErrors,
  onAction
}) {
  return useMutation(
    async () => {
      const payload = {
        profileId: paymentPlan.profile_id,
        paymentPlanId: paymentPlan.id,
        data: {
          description: feeDescription,
          amount: data.amount * 100
        }
      }

      if (fee) {
        return api.updatePaymentPlanFee({
          ...payload,
          feeId: fee.id
        })
      } else {
        return api.createPaymentPlanFee(payload)
      }
    },
    {
      onSuccess: (payload) => {
        if (data.payer === 'external_payer') {
          onAction({ name: 'new_fee_with_external_payer', data: payload })
        } else {
          onAction({ name: 'new_fee' })
        }
      },
      onError: (error) =>
        setErrors({ ...error?.data['book_keeper/payment_plan/fee'] })
    }
  )
}

export default function NewPlanFeeModal({
  isOpen,
  onClose,
  onAction,
  paymentPlan,
  fee
}) {
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})

  const feeDescription =
    fee?.description ||
    t('Fee #{{feeNr}}', {
      feeNr: paymentPlan.fees.length + 1
    })

  const externalPayersFeatureEnabled = useSettingByName(
    'modules.financial.features.external_payers.enable'
  )

  const { mutate: removePaymentPlanFee, isLoading: isDeleting } =
    useRemovePaymentPlanFee({
      paymentPlan,
      fee,
      setErrors,
      onClose
    })

  const {
    mutate: createOrUpdatePaymentPlanFee,
    isLoading: isCreatingOrUpdating
  } = useCreateOrUpdatePaymentPlanFee({
    paymentPlan,
    fee,
    feeDescription,
    data,
    setErrors,
    onAction
  })

  const handleSubmit = () => createOrUpdatePaymentPlanFee()
  const handleDelete = () => removePaymentPlanFee()

  return (
    <Modal
      role='dialog'
      aria-modal='true'
      aria-labelledby='NewPlanFeeModal'
      size='medium'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <span id='NewPlanFeeModal'>
              {fee ? <Trans>Edit fee</Trans> : <Trans>Add fee</Trans>}
            </span>
          }
          onClose={onClose}
        />
      }
      footer={
        <ModalButtons>
          {fee && (
            <Button
              onClick={handleDelete}
              loading={isDeleting}
              className={styles.deleteBtn}
              negative
            >
              <Trans>Remove fee</Trans>
            </Button>
          )}
          <Button onClick={onClose} disabled={isCreatingOrUpdating}>
            <Trans>Close</Trans>
          </Button>
          <Button primary onClick={handleSubmit} loading={isCreatingOrUpdating}>
            {fee ? (
              <Trans>Save changes</Trans>
            ) : data.payer === 'external_payer' ? (
              <Trans>Define payer</Trans>
            ) : (
              <Trans>Add fee</Trans>
            )}
          </Button>
        </ModalButtons>
      }
    >
      <Field
        id='amount'
        type='number'
        label={<Trans>Fee amount</Trans>}
        required
        defaultValue={fee?.amount && fee.amount / 100}
        onChange={(id, value) => setData({ ...data, [id]: value })}
        error={errors.amount}
      />
      {!fee && externalPayersFeatureEnabled && (
        <Field
          id='payer'
          type='radio'
          label={<Trans>Who is going to pay?</Trans>}
          required
          onChange={(id, value) => {
            setData({ ...data, [id]: value })
          }}
          value={data.payer || 'applicant'}
          inputOptions={[
            {
              value: 'applicant',
              label: <Trans>Myself</Trans>
            },
            {
              value: 'external_payer',
              label: <Trans>External payer</Trans>
            }
          ]}
        />
      )}
    </Modal>
  )
}
