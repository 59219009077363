import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldStyleDropdown from '../FieldStyleDropdown'

import styles from './styles.module.scss'

export default function AlignBlock({
  editor,
  isTextAlign = true,
  horizontal = true,
  vertical = false
}) {
  const { t } = useTranslation()

  return (
    <EditorCard title={t('Alignment')}>
      {horizontal && (
        <FieldStyleDropdown
          className={styles.paddingField}
          editor={editor}
          label={t('Horizontal')}
          name={`typography.${isTextAlign ? 'text-' : ''}align`}
        />
      )}
      {vertical && (
        <FieldStyleDropdown
          className={styles.paddingField}
          editor={editor}
          label={t('Vertical')}
          name='dimension.vertical-align'
        />
      )}
    </EditorCard>
  )
}
