import { useQuery } from 'react-query'

import { isEmpty } from 'lodash-es'

import { useAppData } from 'shared/contexts/AppData'

import api from 'apps/ContentPages/api'
import { sortTemplateData } from './helpers/sort-template-data'

export default function useApplicationTemplatesData(
  targetTemplateIds = [],
  fetchRegistrations
) {
  const { applics } = useAppData()

  const canFetchTemplates = !isEmpty(targetTemplateIds)

  const { data: { templates, templateClasses } = {}, status } = useQuery(
    ['get-templates-data', targetTemplateIds, fetchRegistrations],
    handleFetchData,
    { enabled: canFetchTemplates }
  )
  const isLoading = status === 'loading'

  let templatesData = []

  if (!isLoading && templates) {
    templatesData = getTemplateLinkedData({
      templates,
      applications: applics,
      templateClasses
    })

    sortTemplateData(templatesData)
  }

  return { isLoading, templatesData }
}

const handleFetchData = async ({
  queryKey: [_key, targetTemplateIds, fetchRegistrations]
}) => {
  const getTemplates = fetchRegistrations
    ? api.getRegistrationTemplates
    : api.getApplicationTemplates

  const templates = await getTemplates(targetTemplateIds)

  const templateClassesIds = (templates || [])
    .filter(isClassSpecificTemplate)
    .map((template) => template.context_id)

  const templateClasses = await api.getClassesState(
    uniqueElements(templateClassesIds)
  )

  return { templates, templateClasses }
}

const uniqueElements = (arr) => [...new Set(arr)]

const isClassSpecificTemplate = (template) =>
  template.context_type === 'Institutions::ClassOf' && template.context_id

const getTemplateLinkedData = ({
  templates,
  applications,
  templateClasses
}) => {
  const applicsByTemplateId = (applications || []).reduce(
    (hashed, applic) => ({
      ...hashed,
      [applic.template_id]: [...(hashed[applic.template_id] || []), applic]
    }),
    {}
  )

  const templateClassesById = (templateClasses || []).reduce(
    (hashed, classOfTemplate) => ({
      ...hashed,
      [classOfTemplate.id]: classOfTemplate
    }),
    {}
  )

  return templates.map((template) => {
    const classOfTemplate = isClassSpecificTemplate(template)
      ? templateClassesById[template.context_id]
      : null

    const linkedApplications = applicsByTemplateId[template.id]

    const linkedApplication = linkedApplications && linkedApplications[0]

    return { template, classOfTemplate, linkedApplication, linkedApplications }
  })
}
