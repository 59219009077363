import React from 'react'
import { useQuery } from 'react-query'

import api from 'apps/ContentPages/api'
import templateHasContextSelection from 'apps/ContentPages/utils/is-context-selection-required'
import { getTemplateState, OPENED_STATES } from '../helpers/template-state'
import {
  useClassOfTemplate,
  useLinkedApplication,
  useLinkedApplications,
  useTemplate
} from '../template-info-context'
import ApplicationActions from './actions'
import TemplateDate from './date'
import LoadingActionsPlaceholder from './loading-actions-placeholder'
import TemplateName from './name'
import ProgrammeAndClassModal from './programme-and-class-modal'
import { StartApplicationStateProvider } from './start-application-state-context'
import SubmittedApplications from './submitted-applications'
import ThirdPartyApplicationModal from './third-party-application-modal'

const TemplateInfo = ({ widgetId }) => {
  const template = useTemplate()
  const linkedApplication = useLinkedApplication()
  const linkedApplications = useLinkedApplications()
  const classOfTemplate = useClassOfTemplate()

  const { data: applicationContexts, status: fetchingStatus } = useQuery(
    ['getTemplateChoices', template.id, widgetId],
    () => api.getTemplateChoices(template.id),
    { enabled: templateHasContextSelection(template) }
  )

  const templateState = React.useMemo(
    () =>
      getTemplateState({
        template,
        application: linkedApplication,
        classOfTemplate
      }),
    [template, linkedApplication, classOfTemplate]
  )

  const isAcceptingApplications = OPENED_STATES.includes(templateState)

  const submittedApplications = React.useMemo(
    () => (linkedApplications || []).filter((app) => app.state === 'submitted'),
    [linkedApplications]
  )

  return (
    <div data-testid='template-info'>
      <TemplateName />
      <TemplateDate state={templateState} />
      {fetchingStatus === 'loading' ? (
        <LoadingActionsPlaceholder />
      ) : (
        <>
          <SubmittedApplications
            template={template}
            submittedApplications={submittedApplications}
            contexts={applicationContexts}
          />
          {isAcceptingApplications && (
            <StartApplicationStateProvider templateData={template}>
              <ApplicationActions contexts={applicationContexts} />
              <ProgrammeAndClassModal />
              <ThirdPartyApplicationModal />
            </StartApplicationStateProvider>
          )}
        </>
      )}
    </div>
  )
}

export default TemplateInfo
