import React from 'react'

import PaddingBlock from '../PaddingBlock'

export default function NavigationSettings({ editor }) {
  return (
    <>
      <PaddingBlock editor={editor} />
    </>
  )
}
