import { utils } from '@fullfabric/public-api'

export default async function createExternalPayerRequest({
  payableId,
  payerEmail,
  payerName,
  requiresApproval
}) {
  const response = await fetch(`/book_keeper/api/external_payer_requests`, {
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify({
      payable_id: payableId,
      email: payerEmail,
      name: payerName,
      requires_approval: requiresApproval
    })
  })

  return await utils.checkResponse(response)
}
