import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import {
  Button,
  Field,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import duplicateEmailTemplate from 'shared/api/duplicateEmailTemplate'

import styles from './styles.module.scss'

function DuplicateModal({ targetId, initialName, isOpen, handleClose }) {
  const history = useHistory()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: `${initialName} copy`
    }
  })

  const { ref: nameInputRef, ...nameInputProps } = register('name', {
    required: t("Can't be blank")
  })

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const duplicateTemplateMutation = useMutation(
    ({ originId, formValues }) => duplicateEmailTemplate(originId, formValues),
    {
      onSuccess: (data) => {
        if (data.editor === 'classic') {
          window.top.location.assign(`/email_templates#${data.id}/editor`)
        } else {
          history.push(`/shared_email_templates/${data.id}`)
        }
      }
    }
  )

  const onSubmit = useCallback(
    async (formValues) => {
      duplicateTemplateMutation.mutate({ originId: targetId, formValues })
    },
    [duplicateTemplateMutation, targetId]
  )

  const closeModal = () => {
    handleClose()
    reset()
  }

  return (
    <Modal
      role='dialog'
      aria-labelledby='duplicate-modal-title'
      size='small'
      isOpen={isOpen}
      header={
        <ModalTitle
          title={
            <span id='duplicate-modal-title'>
              {t('Duplicate email template')}
            </span>
          }
          onClose={closeModal}
        />
      }
      footer={
        <ModalButtons>
          <Button
            onClick={closeModal}
            disabled={duplicateTemplateMutation.isLoading}
          >
            {t('Cancel')}
          </Button>

          <Button
            primary
            type='submit'
            form='duplicate-modal-form'
            loading={duplicateTemplateMutation.isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('Duplicate template')}
          </Button>
        </ModalButtons>
      }
    >
      <form id='duplicate-modal-form'>
        <Field
          {...nameInputProps}
          inputRef={nameInputRef}
          type='text'
          label={t('Name')}
          error={errors.name?.message}
          className={styles.field}
        />
      </form>

      {duplicateTemplateMutation.isError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
          )}
        </Text>
      ) : null}
    </Modal>
  )
}

export default DuplicateModal
