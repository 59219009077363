import React, { useCallback, useEffect, useRef, useState } from 'react'

import grapesjs from 'grapesjs'
import presetMjml from 'grapesjs-mjml'
import { compile } from 'html-to-text'

import GjsEditor from '@grapesjs/react'
import { mergeAttributes } from '@tiptap/core'
import { HardBreak } from '@tiptap/extension-hard-break'
import { Paragraph } from '@tiptap/extension-paragraph'
import { StarterKit } from '@tiptap/starter-kit'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

import './grapejs.scss'

import { debounce } from 'lodash'

import { useEditor } from '@tiptap/react'
import { Loading } from '@fullfabric/alma-mater'

import { baseTemplateGrapejs } from 'apps/EmailTemplates/constants/baseTemplateGrapejs'
import { useEmailTemplateImages } from 'apps/EmailTemplates/hooks/useEmailTemplateImages'
import { useMergeTags } from 'apps/EmailTemplates/hooks/useMergeTags'
import { tipTapExtensions } from '../PlainTextEditor'
import { getGrapeJsOptionsConfig } from './config'
import { ImageUploadModal } from './ImageUploadModal'
import { mergeTagPlugin } from './plugins/mergeTagPlugin'
import { textEditorPlugin } from './plugins/textEditorPlugin'
import { Sidebar } from './Sidebar'
import { Toolbar } from './Toolbar'

import styles from './styles.module.scss'

const compiledConvert = compile({})

const customExtensions = [
  HardBreak.extend({
    addKeyboardShortcuts() {
      return {
        Enter: () => this.editor.commands.setHardBreak()
      }
    }
  }),
  Paragraph.extend({
    renderHTML({ HTMLAttributes }) {
      return [
        'p',
        mergeAttributes({ style: 'margin: 0; padding: 0;' }, HTMLAttributes),
        0
      ]
    }
  })
]

export default function GrapejsEditor({ emailTemplate, onSave, onChange }) {
  const [modalOpen, setModalOpen] = React.useState(false)
  const mergeTags = useMergeTags()

  const tipTapEditor = useEditor({
    extensions: [
      StarterKit.configure({ paragraph: false, hardBreak: false }),
      ...tipTapExtensions,
      ...customExtensions
    ]
  })

  // Just a trigger to re-render the component when the editor is ready
  const [, setHasEditor] = useState(false)
  const editorRef = useRef(null)
  const colors = useSettingByName(
    'modules.core.submodules.email_templates.themes.colors'
  )
  const { data: dataImages, isLoading } = useEmailTemplateImages(
    emailTemplate.id
  )
  const onEditorSave = debounce((editor) => {
    const html = editor.runCommand('mjml-code-to-html')?.html
    const mjml = editor.runCommand('mjml-code')

    onSave({ html, mjml, text: compiledConvert(html) })
  }, 500)

  const onEditor = (grapeEditor) => {
    editorRef.current = grapeEditor
    grapeEditor.addComponents(emailTemplate.mjml || baseTemplateGrapejs)
    grapeEditor.Panels.removeButton('options', 'export-template')
    grapeEditor.Panels.removeButton('options', 'mjml-import')
    // Override the open-assets command to open the modal
    grapeEditor.Commands.add('open-assets', {
      run() {
        setModalOpen(true)
      }
    })
    setHasEditor(grapeEditor)
  }

  const onGrapeJsUpdate = (_, editor) => {
    onChange()
    onEditorSave(editor)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <ImageUploadModal
        editor={editorRef.current}
        emailTemplate={emailTemplate}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <Sidebar editor={editorRef.current} emailTemplate={emailTemplate} />
      <div className={styles.editorWithToolbarContainer}>
        <Toolbar editor={editorRef.current} />

        <GjsEditor
          aria-label='Grapejs email editor'
          role='main'
          style={{ width: 'initial' }}
          grapesjs={grapesjs}
          options={getGrapeJsOptionsConfig(
            colors,
            emailTemplate,
            mergeTags,
            dataImages,
            tipTapEditor
          )}
          onUpdate={onGrapeJsUpdate}
          plugins={[textEditorPlugin, presetMjml, mergeTagPlugin]}
          onEditor={onEditor}
        />
      </div>
    </div>
  )
}
