import React from 'react'
import { useTranslation } from 'react-i18next'

import BackgroundBlock from '../Background'
import BorderRadiusBlock from '../BorderRadius'
import EditorCard from '../EditorCard'
import FieldTraitDropdown from '../FieldTraitDropdown'
import FieldTraitSize from '../FieldTraitSize'
import PaddingBlock from '../PaddingBlock'

export default function SocialElementSettings({ editor }) {
  const { t } = useTranslation()

  return (
    <>
      <EditorCard>
        <FieldTraitDropdown editor={editor} label={t('Icon')} name='name' />
        {editor.getSelected().getTrait('name')?.getValue() === 'custom' && (
          <FieldTraitSize editor={editor} label={t('Icon image')} name='src' />
        )}
        <FieldTraitSize editor={editor} label={t('Link address')} name='href' />
      </EditorCard>
      <PaddingBlock editor={editor} />
      <BackgroundBlock editor={editor} />
      <BorderRadiusBlock editor={editor} />
    </>
  )
}
