import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, Text } from '@fullfabric/alma-mater'

import SidebarPanel from '../SidebarPanel'
import BodySettings from './BodySettings'
import ButtonStyles from './ButtonSettings'
import ColumnStyles from './ColumnSettings'
import DividerStyles from './DividerSettings'
import ImageStyles from './ImageStyles'
import NavigationLinkSettings from './NavigationLinkSettings'
import NavigationSettings from './NavigationSettings'
import SectionStyles from './SectionSettings'
import SocialElementSettings from './SocialElementSettings'
import GroupSocialSettings from './SocialSettings'
import SpacerSettings from './SpacerSettings'
import TextStyles from './TextStyles'

import styles from './styles.module.scss'

const typeToComponent = {
  'mj-section': SectionStyles,
  'mj-image': ImageStyles,
  'mj-button': ButtonStyles,
  'mj-column': ColumnStyles,
  'mj-divider': DividerStyles,
  'mj-spacer': SpacerSettings,
  'mj-navbar': NavigationSettings,
  'mj-navbar-link': NavigationLinkSettings,
  text: TextStyles,
  'merge-tag': TextStyles,
  'mj-text': TextStyles,
  'mj-social': GroupSocialSettings,
  'mj-social-element': SocialElementSettings
}

export default function SettingsPanel({ active, editor, onDone }) {
  const { t } = useTranslation()
  const [selectedComponent, setSelectedComponent] = useState(null)
  const onSelectElement = useCallback((element) => {
    if (element.get('type') === 'mj-body') {
      setSelectedComponent(null)
    } else {
      setSelectedComponent(element)
    }
  }, [])

  const onRemoveElement = useCallback(() => {
    setSelectedComponent(null)
  }, [])

  useEffect(() => {
    if (!editor) return

    editor.on('component:selected', onSelectElement)
    editor.on('component:remove', onRemoveElement)

    return () => {
      editor.off('component:selected', setSelectedComponent)
      editor.off('component:remove', onRemoveElement)
    }
  }, [editor, onRemoveElement, onSelectElement])

  const onClickBack = useCallback(() => {
    setSelectedComponent(null)
    editor.select(null)
    onDone()
  }, [editor, onDone])

  const typeOrTagName = selectedComponent?.get('type') || 'text'
  const Component = typeToComponent[typeOrTagName]
  const isTextElement = ['mj-text', 'text'].includes(typeOrTagName)

  return (
    <SidebarPanel active={active} expanded={!!selectedComponent} noPadding>
      {!selectedComponent && <BodySettings editor={editor} />}
      {selectedComponent && Component && (
        <>
          <div className={styles.topContainer}>
            <div className={styles.backButton} onClick={onClickBack}>
              <ArrowLeftIcon color='text-base-darker' />
              <Text type='f5' fontColor='text-base-darker'>
                {t('Done')}
              </Text>
            </div>
            <Text type='h4' fontColor='text-base-darkest'>
              {isTextElement ? t('Text Block') : selectedComponent.getName()}
            </Text>
            <div className={styles.backButton} />
          </div>
          <div className={styles.container}>
            <Component
              editor={editor}
              component={selectedComponent}
              key={selectedComponent.get('id')}
            />
          </div>
        </>
      )}
    </SidebarPanel>
  )
}
