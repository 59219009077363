import React, { useEffect, useState } from 'react'

import AttachPanel from './AttachPanel'
import BlocksPanel from './BlocksPanel'
import IconsContainer from './IconsContainer'
import SettingsPanel from './SettingsPanel'

import styles from './styles.module.scss'

export const Sidebar = ({ editor, emailTemplate }) => {
  const [active, setActive] = useState('blocks')

  useEffect(() => {
    const fn = () => setActive('style')
    const fnBlocks = () => setActive('blocks')
    editor?.on('component:selected', fn)
    editor?.on('component:remove', fnBlocks)

    return () => {
      editor?.off('component:selected', fn)
      editor?.off('component:remove', fnBlocks)
    }
  }, [editor])

  return (
    <div className={styles.container}>
      <IconsContainer activeTab={active} onChange={setActive} />
      <BlocksPanel active={active === 'blocks'} editor={editor} />
      <SettingsPanel
        active={active === 'style'}
        editor={editor}
        onDone={() => setActive('blocks')}
      />
      <AttachPanel
        active={active === 'attachment'}
        emailTemplate={emailTemplate}
      />
    </div>
  )
}
