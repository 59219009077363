import React from 'react'

import { setCookieLocale } from 'shared/utils/getInitialLocale'

const LocaleContext = React.createContext({})
const SetLocaleContext = React.createContext({})

export function LocaleProvider({ initialLocale, locales, children }) {
  const alternativeLocale = getAlternativeLocale(locales, initialLocale)

  const localesState = React.useMemo(
    () => ({ locale: initialLocale, alternativeLocale, allLocales: locales }),
    [initialLocale, alternativeLocale, locales]
  )

  return (
    <LocaleContext.Provider value={localesState}>
      <SetLocaleContext.Provider value={setCookieLocale}>
        {children}
      </SetLocaleContext.Provider>
    </LocaleContext.Provider>
  )
}

export function useLocale() {
  const localeState = React.useContext(LocaleContext)

  return localeState
}

export function useSetLocale() {
  const setLocale = React.useContext(SetLocaleContext)

  return setLocale
}

function getAlternativeLocale(locales, currentLocale) {
  return locales.find((l) => l !== currentLocale)
}
