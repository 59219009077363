import React, { useEffect, useState } from 'react'

import { Field } from '@fullfabric/alma-mater'

import { useTenantColors } from 'apps/EmailTemplates/hooks/useTenantColors'

import classNames from 'classnames'

export default function FieldStyleSize({
  editor,
  className,
  label,
  name,
  prefixLabel = 'px',
  placeholder = '0',
  isColorPicker = false,
  inputOptions = {}
}) {
  const [initialValue, setInitialValue] = useState('')
  const [sectorId, mainPropertyOrPropertyId, propertyId] = name.split('.')
  const sector = editor.StyleManager.getSector(sectorId)
  const mainPropertyOrProperty = sector.getProperty(mainPropertyOrPropertyId)
  const property = propertyId && mainPropertyOrProperty.getProperty(propertyId)
  const elementRef = React.useRef()
  const cursorRef = React.useRef(0)
  const colors = useTenantColors()

  useEffect(() => {
    const updateValues = () => {
      setInitialValue((property || mainPropertyOrProperty)?.getValue() || '')
    }

    updateValues() // Set the initial values
    editor.on('style:custom', updateValues)

    return () => editor.off('style:custom', updateValues)
  }, [
    sector,
    mainPropertyOrPropertyId,
    propertyId,
    property,
    mainPropertyOrProperty,
    editor
  ])

  useEffect(() => {
    elementRef.current?.setSelectionRange?.(
      cursorRef.current,
      cursorRef.current
    )
  }, [initialValue])
  const internalInputOptions = isColorPicker
    ? {
        ...inputOptions,
        defaultColors: colors
      }
    : {
        prefixLabel,
        placeholder,
        ...inputOptions
      }

  return (
    <Field
      id={propertyId || mainPropertyOrPropertyId}
      type={isColorPicker ? 'colorPicker' : 'prefixedText'}
      value={`${initialValue === placeholder ? '' : initialValue || ''}`}
      className={classNames(className)}
      label={label}
      inputRef={elementRef}
      onChange={(eventOrValue) => {
        const value = eventOrValue?.target?.value || eventOrValue
        if (!isColorPicker) {
          cursorRef.current = eventOrValue.target.selectionStart
        }
        ;(property || mainPropertyOrProperty).upValue(
          value + (isColorPicker ? '' : 'px')
        )
      }}
      inputOptions={internalInputOptions}
    />
  )
}
