import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'

import { flatMap, some } from 'lodash'
import PropTypes from 'prop-types'

import { Dropdown, IconButton, MoreIcon } from '@fullfabric/alma-mater'
import { usePermission } from '@fullfabric/authorization-officer'

import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

import editBillingEvent from 'apps/Billing/api/editBillingEvent'
import {
  getEventGroupsUnderContractQueryKeyFilter,
  getEventsUnderEventGroupQueryKeyFilter
} from 'apps/Billing/constants/queryParts'

import styles from './styles.module.scss'

export default function EventActionsCell({ event, contractId, eventGroupId }) {
  const { t } = useTranslation()

  const hasBillableChangePermission = usePermission('billing.billable_change')

  if (!hasBillableChangePermission) {
    return null
  }

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <IconButton plain>
          <MoreIcon />
        </IconButton>
      </Dropdown.Toggle>

      <Dropdown.Menu
        aria-label={t('Row options menu')}
        className={styles.dropdownMenu}
      >
        <EventBillableNonBillableOption
          contractId={contractId}
          event={event}
          eventGroupId={eventGroupId}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

EventActionsCell.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    billable: PropTypes.bool.isRequired
  }).isRequired,
  contractId: PropTypes.string.isRequired,
  eventGroupId: PropTypes.string.isRequired
}

function EventBillableNonBillableOption({ contractId, eventGroupId, event }) {
  const { t } = useTranslation()

  const editBillableAttributeMutation = useEventBillableMutation(
    contractId,
    eventGroupId,
    event.id
  )

  const labelId = 'billable-non-billable-option-' + event.id

  if (event.billable) {
    return (
      <Dropdown.Menu.Option
        onClick={() =>
          editBillableAttributeMutation.mutate({ billable: false })
        }
        aria-labelledby={labelId}
      >
        <span id={labelId}>{t('Mark as non-billable')}</span>
      </Dropdown.Menu.Option>
    )
  }

  return (
    <Dropdown.Menu.Option
      onClick={() => editBillableAttributeMutation.mutate({ billable: true })}
      aria-labelledby={labelId}
    >
      <span id={labelId}>{t('Mark as billable')}</span>
    </Dropdown.Menu.Option>
  )
}

/**
 * Changes the billable status of an event via the API. Then, updates the event
 * itself in the query cache and, if necessary, also the event group to which it
 * belongs.
 */
function useEventBillableMutation(contractId, eventGroupId, eventId) {
  const queryClient = useQueryClient()

  const updateEventPages = usePagedQueriesUpdater(
    getEventsUnderEventGroupQueryKeyFilter(eventGroupId)
  )

  const updateEventGroupPages = usePagedQueriesUpdater(
    getEventGroupsUnderContractQueryKeyFilter(contractId)
  )

  const onSuccess = (editedEvent) => {
    // Mark the event as billable:
    updateEventPages((page) => {
      page.data.forEach((event) => {
        if (editedEvent.id === event.id) {
          event.billable = editedEvent.billable
        }
      })
    })

    // Mark the event group as billable if any event is billable:
    const eventGroupBillable =
      editedEvent.billable || anyEventBillable(queryClient, eventGroupId)

    updateEventGroupPages((page) => {
      page.data.forEach((eventGroup) => {
        if (eventGroup.id === eventGroupId) {
          eventGroup.billable = eventGroupBillable
        }
      })
    })
  }

  return useMutation(({ billable }) => editBillingEvent(eventId, billable), {
    onSuccess
  })
}

function anyEventBillable(queryClient, eventGroupId) {
  const eventsQueries = queryClient.getQueriesData(
    getEventsUnderEventGroupQueryKeyFilter(eventGroupId)
  )

  const events = flatMap(eventsQueries, ([_filter, query]) =>
    query.pages.flatMap((page) => page.data)
  )

  return some(events, (event) => event.billable)
}
