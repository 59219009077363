import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldStyleSize from '../FieldStyleSize'

import styles from './styles.module.scss'

export default function SizeBlock({ editor, hideHeight, hideWidth }) {
  const { t } = useTranslation()

  return (
    <EditorCard title={t('Size')}>
      {!hideWidth && (
        <FieldStyleSize
          className={styles.paddingField}
          editor={editor}
          label={t('Width')}
          name='dimension.width'
          placeholder='auto'
        />
      )}
      {!hideHeight && (
        <FieldStyleSize
          className={styles.paddingField}
          editor={editor}
          label={t('Height')}
          name='dimension.height'
          placeholder='auto'
        />
      )}
    </EditorCard>
  )
}
