import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { AddIcon, Col, Field, Link, Row } from '@fullfabric/alma-mater'

import getSubjects from 'apps/StudyPlans/api/getSubjects'
import {
  useOnChangeSubjectEditionData,
  useSetSubjectEditionData,
  useSubjectEditionData,
  useSubjectEditionErrors
} from 'apps/StudyPlans/contexts/SubjectEditionData'

import styles from './styles.module.scss'

const ERROR_MESSAGES = {
  blank: <Trans>Can't be blank</Trans>,
  duplicate: <Trans>This subject already exists in the study plan</Trans>
}

const SubjectField = () => {
  const subjectData = useSubjectEditionData()
  const setSubjectData = useSetSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()
  const errors = useSubjectEditionErrors()

  const { data: subjectsList, isLoading } = useQuery(
    ['subjects', { light: true }],
    async () => getSubjects({ light: true }),
    {
      staleTime: 1000 * 60 * 5 // 5 minutes
    }
  )

  const toggleCreateSubject = React.useCallback(() => {
    setSubjectData((subjData) => ({
      ...subjData,
      course_id: null,
      unit_ids: [],
      createSubjectBeforeAdding: !subjData.createSubjectBeforeAdding
    }))
  }, [setSubjectData])

  return (
    <>
      <Link
        className={styles.createSubjectBeforeAdding}
        onClick={toggleCreateSubject}
      >
        {subjectData.createSubjectBeforeAdding ? (
          <Trans>Select an existing subject</Trans>
        ) : (
          <Trans>Create new subject</Trans>
        )}
        <AddIcon />
      </Link>

      {subjectData.createSubjectBeforeAdding ? (
        <Row>
          <Col xs={12} xl={4}>
            <Field
              id='subject_code'
              type='text'
              label={<Trans>Add a subject code</Trans>}
              onChange={onChange}
              value={subjectData.subject_code}
              required
              error={ERROR_MESSAGES[errors.subject_code]}
            />
          </Col>

          <Col xs={12} xl={8}>
            <Field
              id='subject_name'
              type='text'
              label={<Trans>Add a subject name</Trans>}
              onChange={onChange}
              value={subjectData.subject_name}
              required
              error={ERROR_MESSAGES[errors.subject_name]}
            />
          </Col>
        </Row>
      ) : (
        <Field
          id='course_id'
          type='autocomplete'
          label={<Trans>Subject</Trans>}
          value={subjectData.course_id || ''}
          onChange={onChange}
          isLoading={isLoading}
          inputOptions={{
            // isLoading: isLoading,
            placeholder: <Trans>- Select -</Trans>,
            options: (subjectsList || []).map((subject) => ({
              value: subject.id,
              label: subject.long_name
            }))
          }}
          required
          error={ERROR_MESSAGES[errors.course_id]}
        />
      )}
    </>
  )
}

export default SubjectField
