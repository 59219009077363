import { useQueryClient } from 'react-query'

import { cloneDeep } from 'lodash'

/**
 * Returns a function that updates the data for a paged query.
 *
 * The function returned by this hook takes a callback function as an argument
 * that is called with each page of the query data. The callback function can
 * then imperatively update the page data as needed.
 *
 * @example
 *
 *     const update = usePagedQueriesUpdater("/an/endpoint")
 *
 *     update((page, pageIdx) => {
 *       page.data.forEach((item, itemIdx) => {
 *         if (item.id === 123) {
 *           item.name = "New name"
 *         }
 *       })
 *     })
 *
 * @param {String|Array} keyOrFilter The key or filter to update.
 * @returns {Function} A function that updates the data for a paged query.
 */
export function usePagedQueriesUpdater(keyOrFilter) {
  const queryClient = useQueryClient()

  return (cb) => {
    queryClient.setQueriesData(keyOrFilter, (oldData) => {
      const newData = cloneDeep(oldData)
      newData.pages.forEach((page, idx) => cb(page, idx))
      return newData
    })
  }
}
