import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { isNil } from 'lodash'
import PropTypes from 'prop-types'

import { Dropdown, IconButton, MoreIcon } from '@fullfabric/alma-mater'
import { usePermission } from '@fullfabric/authorization-officer'

import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

import makeEventGroupBillable from 'apps/Billing/api/makeEventGroupBillable'
import makeEventGroupUnbillable from 'apps/Billing/api/makeEventGroupUnbillable'
import { getEventGroupsUnderContractQueryKeyFilter } from 'apps/Billing/constants/queryParts'

export default function EventGroupActionsCell({
  row,
  data,
  billableType,
  onShowEventGroupEvents,
  onChangeProfileBillableStatus
}) {
  const eventGroup = data[row.index]
  const { t } = useTranslation()

  const hasBillableChangePermission = usePermission('billing.billable_change')

  return (
    <Dropdown>
      <Dropdown.Toggle>
        <IconButton plain>
          <MoreIcon />
        </IconButton>
      </Dropdown.Toggle>

      <Dropdown.Menu aria-label={t('Row actions menu')}>
        <SeeEventsOption
          billableType={billableType}
          eventGroup={eventGroup}
          onClick={() => onShowEventGroupEvents(eventGroup.id)}
        />

        {hasBillableChangePermission && (
          <>
            <EventGroupBillableNonBillableOption eventGroup={eventGroup} />
            <ProfileBillableNonBillableOption
              eventGroup={eventGroup}
              onChangeProfileBillableStatus={onChangeProfileBillableStatus}
            />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

EventGroupActionsCell.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired
  }).isRequired,
  data: PropTypes.array.isRequired,
  billableType: PropTypes.string.isRequired,
  onShowEventGroupEvents: PropTypes.func.isRequired,
  onChangeProfileBillableStatus: PropTypes.func.isRequired
}

function SeeEventsOption({ billableType, eventGroup, onClick }) {
  const { t } = useTranslation()

  const labelId = 'see-events-option-' + eventGroup.id

  let label
  switch (billableType) {
    case 'applic':
      label = t('See submissions')
      break
    case 'engagement':
      label = t('See engagements')
      break
    case 'registration':
      label = t('See registrations')
      break
    default:
      throw new Error(`Unknown billable type: ${billableType}`)
  }

  return (
    <Dropdown.Menu.Option onClick={onClick} aria-labelledby={labelId}>
      <span id={labelId}>{label}</span>
    </Dropdown.Menu.Option>
  )
}

function EventGroupBillableNonBillableOption({ eventGroup }) {
  const { t } = useTranslation()
  const { contract_id: contractId } = eventGroup

  const [makeEventGroupBillableMutation, makeEventGroupUnbillableMutation] =
    useEventGroupBillableMutation(contractId)

  const labelId = 'billable-non-billable-option-' + eventGroup.id

  if (eventGroup.billable) {
    return (
      <Dropdown.Menu.Option
        onClick={() => makeEventGroupUnbillableMutation.mutate(eventGroup.id)}
        aria-labelledby={labelId}
      >
        <span id={labelId}>{t('Mark as non-billable')}</span>
      </Dropdown.Menu.Option>
    )
  }

  return (
    <Dropdown.Menu.Option
      onClick={() => makeEventGroupBillableMutation.mutate(eventGroup.id)}
      aria-labelledby={labelId}
    >
      <span id={labelId}>{t('Mark as billable')}</span>
    </Dropdown.Menu.Option>
  )
}

function ProfileBillableNonBillableOption({
  eventGroup,
  onChangeProfileBillableStatus
}) {
  const { t } = useTranslation()

  const labelId = 'billable-non-billable-profile-option-' + eventGroup.id

  // If the value isn't set, the most likely scenario is that the profile
  // was deleted.
  if (isNil(eventGroup.profile_is_billable)) {
    return (
      <Dropdown.Menu.Option disabled aria-labelledby={labelId}>
        <span id={labelId}>{t('Mark profile as non-billable')}</span>
      </Dropdown.Menu.Option>
    )
  }

  if (eventGroup.profile_is_billable) {
    return (
      <Dropdown.Menu.Option
        onClick={() =>
          onChangeProfileBillableStatus(eventGroup.profile_id, {
            makeBillable: false
          })
        }
        aria-labelledby={labelId}
      >
        <span id={labelId}>{t('Mark profile as non-billable')}</span>
      </Dropdown.Menu.Option>
    )
  }

  return (
    <Dropdown.Menu.Option
      onClick={() =>
        onChangeProfileBillableStatus(eventGroup.profile_id, {
          makeBillable: true
        })
      }
      aria-labelledby={labelId}
    >
      <span id={labelId}>{t('Mark profile as billable')}</span>
    </Dropdown.Menu.Option>
  )
}

function useEventGroupBillableMutation(contractId) {
  const updatePages = usePagedQueriesUpdater(
    getEventGroupsUnderContractQueryKeyFilter(contractId)
  )

  const onSuccess = (editedEventGroup) => {
    updatePages((page) => {
      page.data.forEach((eventGroup) => {
        if (editedEventGroup.id === eventGroup.id) {
          eventGroup.billable = editedEventGroup.billable
        }
      })
    })
  }

  const makeEventGroupBillableMutation = useMutation(
    (eventGroupId) => makeEventGroupBillable(eventGroupId),
    { onSuccess }
  )

  const makeEventGroupUnbillableMutation = useMutation(
    (eventGroupId) => makeEventGroupUnbillable(eventGroupId),
    { onSuccess }
  )

  return [makeEventGroupBillableMutation, makeEventGroupUnbillableMutation]
}

EventGroupActionsCell.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired
  }).isRequired,
  data: PropTypes.array.isRequired,
  billableType: PropTypes.string.isRequired,
  onShowEventGroupEvents: PropTypes.func.isRequired
}
