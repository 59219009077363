import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldStyleDropdown from '../FieldStyleDropdown'
import FieldStyleSize from '../FieldStyleSize'

import styles from './styles.module.scss'

export default function TypographyBlock({ editor, hideAlign, hideTextSize }) {
  const { t } = useTranslation()

  return (
    <EditorCard title={t('Typography')}>
      <div className={styles.container}>
        <FieldStyleDropdown
          className={styles.paddingField}
          editor={editor}
          name={`typography.font-family`}
        />
        <div className={styles.paddingFields}>
          {!hideTextSize && (
            <FieldStyleSize
              className={styles.paddingField}
              editor={editor}
              name={`typography.font-size`}
              prefixLabel='px'
            />
          )}
          <FieldStyleSize
            className={styles.paddingField}
            editor={editor}
            label={hideTextSize ? t('Color') : undefined}
            name={`typography.color`}
            prefixLabel=''
          />
          {!hideAlign && (
            <FieldStyleDropdown
              className={styles.paddingField}
              editor={editor}
              label={t('Align')}
              name={`typography.text-align`}
            />
          )}
        </div>
      </div>
    </EditorCard>
  )
}
