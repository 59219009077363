import React from 'react'
import { animated, useSpring } from 'react-spring'

import { FeedbackMessage } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function FeedbackPopupMessage({
  isDisplayed,
  type,
  onAfterTimeout,
  message
}) {
  const popupAnimation = useSpring({
    bottom: isDisplayed ? '0px' : '-150px'
  })

  return (
    <animated.div
      aria-hidden={!isDisplayed}
      role='dialog'
      aria-labelledby={`${type}-popup-message-${message?.replace(/\s+/g, '-')}`}
      className={styles.feedbackMessageContainer}
      style={popupAnimation}
    >
      <FeedbackMessage
        isOpen
        closeTimeout={2000}
        onClosedAfterTimeout={onAfterTimeout}
        type={type}
        className={styles.feedbackMessage}
        message={
          <span id={`${type}-popup-message-${message?.replace(/\s+/g, '-')}`}>
            {message}
          </span>
        }
      />
    </animated.div>
  )
}
