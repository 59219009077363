import React from 'react'

import BackgroundBlock from '../Background'
import SizeBlock from '../SizeBlock'

export default function SpacerSettings({ editor }) {
  return (
    <>
      <SizeBlock editor={editor} hideWidth />
      <BackgroundBlock editor={editor} />
    </>
  )
}
