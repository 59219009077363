import { getTemplateState, OPENED_STATES } from './template-state'

function flattenData(data) {
  return data.some(Array.isArray) ? data.flat(1) : data
}

function hasSubmittedApplications(linkedApplications) {
  return linkedApplications?.some(
    (application) => application.state === 'submitted'
  )
}

function safeClosesAt(template) {
  // If closes_at is null, treat it as Infinity (so it sorts to the bottom).
  return template.closes_at ?? Number.POSITIVE_INFINITY
}

function sortByClosesAt(a, b) {
  const aCloses = safeClosesAt(a.template)
  const bCloses = safeClosesAt(b.template)
  return aCloses - bCloses
}

function sortByName(a, b) {
  return a.template.long_name.localeCompare(b.template.long_name)
}

function sortByClosesAtAndName(a, b) {
  const diff = sortByClosesAt(a, b)
  if (diff !== 0) {
    return diff
  }
  return sortByName(a, b)
}

function isSubmitted({ linkedApplication, linkedApplications }) {
  return (
    linkedApplication?.state === 'submitted' ||
    hasSubmittedApplications(linkedApplications)
  )
}

function isStarted({ linkedApplication }) {
  return (
    linkedApplication?.state === 'started' ||
    linkedApplication?.state === 'pending_payment'
  )
}

function isOpen({ template, linkedApplication, classOfTemplate }) {
  const templateState = getTemplateState({
    template,
    linkedApplication,
    classOfTemplate
  })
  return OPENED_STATES.includes(templateState)
}

function isFuture({ template }, now = Date.now()) {
  return template.opens_at > now
}

export function sortTemplateData(data) {
  const now = Date.now()

  return flattenData(data).sort((a, b) => {
    const aIsStarted = isStarted(a)
    const bIsStarted = isStarted(b)

    const aIsSubmitted = isSubmitted(a)
    const bIsSubmitted = isSubmitted(b)

    const aIsOpen = isOpen(a)
    const bIsOpen = isOpen(b)

    const aIsFuture = isFuture(a, now)
    const bIsFuture = isFuture(b, now)

    const aIsClosed = !aIsOpen && !aIsFuture
    const bIsClosed = !bIsOpen && !bIsFuture

    // Overall priority: Started > Submitted > Open > Future > Closed
    if (aIsStarted || bIsStarted) {
      if (aIsStarted && bIsStarted) {
        return sortByClosesAtAndName(a, b)
      }
      return aIsStarted ? -1 : 1
    }

    if (aIsSubmitted || bIsSubmitted) {
      if (aIsSubmitted && bIsSubmitted) {
        return sortByClosesAtAndName(a, b)
      }
      return aIsSubmitted ? -1 : 1
    }

    if (aIsOpen || bIsOpen) {
      if (aIsOpen && bIsOpen) {
        return sortByClosesAtAndName(a, b)
      }
      return aIsOpen ? -1 : 1
    }

    if (aIsFuture || bIsFuture) {
      if (aIsFuture && bIsFuture) {
        return sortByName(a, b)
      }
      return aIsFuture ? -1 : 1
    }

    if (aIsClosed || bIsClosed) {
      if (aIsClosed && bIsClosed) {
        return sortByName(a, b)
      }
      return aIsClosed ? -1 : 1
    }

    return 0
  })
}
