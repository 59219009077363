import { utils } from '@fullfabric/public-api'

export default async function makeProfileUnbillable(profileId, { backfill }) {
  const response = await fetch(`/api/profiles/${profileId}/billable`, {
    method: 'DELETE',
    body: JSON.stringify({ backfill }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return await utils.checkResponse(response)
}
