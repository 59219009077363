import React from 'react'
import { useTranslation } from 'react-i18next'

import { useStudyPlan } from 'apps/StudyPlans/contexts/StudyPlanData'

export const getPrerequisiteNames = (
  prerequisites = [],
  studyPlan,
  t = (x) => x
) => {
  const electiveGroups = studyPlan.default_path.course_groups.filter(
    (group) => group.type === 'elective'
  )

  return prerequisites
    .map((prereq) => {
      if (prereq.prerequisite_type === 'Affairs::StudyPlan::Course') {
        const spSubject = studyPlan.courses.find(
          (spSubject) => spSubject.id === prereq.prerequisite_id
        )

        return spSubject?.course?.long_name
      } else {
        const group = electiveGroups.find(
          (group) => group.id === prereq.prerequisite_id
        )

        if (!group) return null

        return t('Elective subjects - {{groupName}}', {
          groupName: group.name
        })
      }
    })
    .filter((x) => x)
}

export const formatPrerequisiteNames = (prerequisites, studyPlan, t) => {
  const list = getPrerequisiteNames(prerequisites, studyPlan, t)
  return list.join(', ')
}

const PrerequisiteNames = ({ prerequisites = [] }) => {
  const { t } = useTranslation()
  const studyPlan = useStudyPlan()

  return <>{getPrerequisiteNames(prerequisites, studyPlan, t).join(',')}</>
}

export default PrerequisiteNames
