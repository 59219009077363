import React, { useCallback, useState } from 'react'
import { Trans } from 'react-i18next'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import { useTemplate } from '../../template-info-context'
import { useTypeOfWidget } from '../../type-of-widget-context'
import {
  useAdvance,
  useCloseModal,
  useModalsState
} from '../start-application-state-context'
import ChoiceDropdowns from './choice-dropdowns'

import styles from './styles.module.scss'

const SUBMISSION_STATES = {
  IDLE: 'IDLE',
  INVALID: 'INVALID',
  VALID: 'VALID',
  SUBMITTING: 'SUBMITTING'
}

const ProgrammeAndClassModal = () => {
  const [showErrors, setShowErrors] = useState(false)
  const [choice, setChoice] = useState({})
  const { isRegistration } = useTypeOfWidget()
  const [submissionState, setSubmissionState] = useState(SUBMISSION_STATES.IDLE)

  const template = useTemplate()

  const {
    programmeAndClass: { visible }
  } = useModalsState()
  const advance = useAdvance()
  const closeModal = useCloseModal()

  const onChoiceChange = useCallback(
    (isValidChoice, choice) => {
      setShowErrors((isShowingErrors) => isShowingErrors && !isValidChoice)
      setSubmissionState(
        isValidChoice ? SUBMISSION_STATES.VALID : SUBMISSION_STATES.INVALID
      )
      setChoice(choice)
    },
    [setChoice, setShowErrors]
  )

  const onSubmit = async () => {
    if (submissionState === SUBMISSION_STATES.INVALID) {
      setShowErrors(true)
      return
    }

    setSubmissionState(SUBMISSION_STATES.SUBMITTING)

    await advance({ context: choice, templateId: template.id })

    setSubmissionState(SUBMISSION_STATES.IDLE)
  }

  return (
    <Modal
      data-testid='application-options-modal'
      size='small'
      className={styles.modal}
      contentClassName={styles.modalBody}
      isOpen={visible}
      header={
        <ModalTitle
          title={
            isRegistration ? (
              <Trans>Registration options</Trans>
            ) : (
              <Trans>Application options</Trans>
            )
          }
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={closeModal}>
            <Trans>Cancel</Trans>
          </Button>
          <Button
            primary
            loading={submissionState === SUBMISSION_STATES.SUBMITTING}
            onClick={onSubmit}
          >
            <Trans>Continue</Trans>
          </Button>
        </ModalButtons>
      }
    >
      <Text type='f4' fontColor='text-base-darkest' marginBottom='default'>
        <Trans>You can change your selection later.</Trans>
      </Text>
      <ChoiceDropdowns onChange={onChoiceChange} showErrors={showErrors} />
    </Modal>
  )
}

export default ProgrammeAndClassModal
