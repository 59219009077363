import React from 'react'

import { DropdownInput } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const fontFamilies = [
  { value: 'Arial, Helvetica, sans-serif', label: 'Arial' },
  { value: '"Arial Black", Gadget, sans-serif', label: 'Arial Black' },
  { value: '"Brush Script MT", sans-serif', label: 'Brush Script MT' },
  { value: '"Comic Sans MS", cursive, sans-serif', label: 'Comic Sans MS' },
  { value: '"Courier New", Courier, monospace', label: 'Courier New' },
  { value: 'Georgia, serif', label: 'Georgia' },
  { value: 'Helvetica, sans-serif', label: 'Helvetica' },
  { value: 'Impact, Charcoal, sans-serif', label: 'Impact' },
  {
    value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    label: 'Lucida Sans Unicode'
  },
  { value: 'Tahoma, Geneva, sans-serif', label: 'Tahoma' },
  { value: '"Times New Roman", Times, serif', label: 'Times New Roman' },
  { value: '"Trebuchet MS", Helvetica, sans-serif', label: 'Trebuchet MS' },
  {
    value: 'Ubuntu, Helvetica, Arial, sans-serif',
    label: 'Ubuntu Helvetica Arial Sans Serif'
  },
  { value: 'Verdana, Geneva, sans-serif', label: 'Verdana' }
]

export const FontFamily = ({ editor }) => {
  const value =
    editor.getAttributes('textStyle').fontFamily ||
    fontFamilies[fontFamilies.length - 2].value

  return (
    <DropdownInput
      id='font-family'
      className={styles.container}
      onChange={(value) => editor.chain().focus().setFontFamily(value).run()}
      value={value || ''}
      options={fontFamilies}
      small
    />
  )
}
