import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import AsyncExportModal from 'shared/components/AsyncExportModal'

const EXPORT_TYPE = 'payment_plan'

export default function PaymentPlansExportModal({
  columns,
  filters,
  isOpen,
  search,
  onClose
}) {
  const exportOpts = useMemo(
    () => ({
      fields_to_show: columns,
      q: search,
      publish_status: filters?.publish_status,
      fee_statuses: filters?.status,
      lifecycles: filters?.lifecycle,
      context_ids: filters?.context_ids
    }),
    [columns, filters, search]
  )

  return (
    <AsyncExportModal
      exportOpts={exportOpts}
      exportType={EXPORT_TYPE}
      isOpen={isOpen}
      title='Export payment plans'
      onClose={onClose}
    />
  )
}

PaymentPlansExportModal.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  search: PropTypes.string,
  onClose: PropTypes.func.isRequired
}
