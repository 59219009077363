import React from 'react'
import { useTranslation } from 'react-i18next'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'

import { SUBJECT_DETAILS_ROUTE } from 'apps/Subjects/components/AppRoutes'
import MoreOptionsCell from './MoreOptionsCell'

export default function SubjectsList() {
  const { t } = useTranslation()

  return (
    <AutoloadDataTable
      fixedLayout
      fetchURL={'/api/tabular_data/subjects'}
      reactQueryProps={{
        staleTime: 0
      }}
      emptyMessage={t('No subjects found.')}
      columns={[
        {
          Header: t('Subject code'),
          accessor: 'code',
          width: 250
        },
        {
          Header: t('Subject name'),
          accessor: 'name',
          flexibleWidth: true,
          type: 'primary',
          linkTo: ({ row, data }) => {
            const subjectId = data[row.index]?.id

            return {
              to: SUBJECT_DETAILS_ROUTE.replace(':id', subjectId)
            }
          }
        },
        {
          Header: '',
          id: 'more-options-col',
          Cell: MoreOptionsCell,
          disableSortBy: true,
          width: 60
        }
      ]}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={paginatedResponseParser}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'name',
        'sort[direction]': 1
      }}
      searchable
      searchParamKey='search'
    />
  )
}
