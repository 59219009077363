export const baseTemplateGrapejs = `
  <mjml>
      <mj-body>
          <mj-section>
          <mj-column>
              <mj-image src="https://assets.unlayer.com/projects/192955/1705058518147-Logo.png" width="85px">
              </mj-image>
          </mj-column>
          </mj-section>
          <mj-section>
          <mj-column width="auto" padding-top="10px" padding-right="10px" padding-left="10px" padding-bottom="10px">
              <mj-text line-height="130%" font-size="24px">
                <span style="font-size: 24px; line-height: 130%;"><strong>Design your email</strong></span>
              </mj-text>
          </mj-column>
          <mj-column width="auto">
              <mj-image src="https://assets.unlayer.com/projects/192955/1705058188949-Placeholder@2x.png">
              </mj-image>
              <mj-text line-height="130%">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </mj-text>
          </mj-column>
          </mj-section>
          <mj-section>
          <mj-column>
              <mj-button align="left" background-color="#0a8ccd">
                Add button text
              </mj-button>
          </mj-column>
          </mj-section>
      </mj-body>
  </mjml>
`
