import React from 'react'
import { useTranslation } from 'react-i18next'

import PropTypes from 'prop-types'

import { Button } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import useModal from 'shared/hooks/useModal'

import ContractExportModal from './ExportButton/ContractExportModal'

import styles from './styles.module.scss'

export default function ExportButton({ billableType, contractId, sort }) {
  const { t } = useTranslation()

  const [isExportModalOpen, handleExportModalOpened, handleExportModalClosed] =
    useModal()

  return (
    <IfPermit to='billing.billing_access'>
      <Button
        className={styles.exportButton}
        primary
        onClick={handleExportModalOpened}
      >
        {t('Export list')}
      </Button>

      <ContractExportModal
        billableType={billableType}
        contractId={contractId}
        sort={sort}
        isOpen={isExportModalOpen}
        onClose={handleExportModalClosed}
      />
    </IfPermit>
  )
}

ExportButton.propTypes = {
  billableType: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  sort: PropTypes.object
}
