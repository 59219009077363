import React from 'react'

import BackgroundBlock from '../Background'
import BorderBlock from '../Border'
import BorderRadiusBlock from '../BorderRadius'
import PaddingBlock from '../PaddingBlock'
import SizeBlock from '../SizeBlock'

export default function ColumnSettings({ editor }) {
  return (
    <>
      <BackgroundBlock editor={editor} isContent />
      <SizeBlock editor={editor} hideHeight />
      <PaddingBlock editor={editor} />
      <BorderBlock editor={editor} />
      <BorderRadiusBlock editor={editor} />
    </>
  )
}
