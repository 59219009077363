import React, { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Loading, SuccessBigIcon, Text } from '@fullfabric/alma-mater'

import InboxNav from 'apps/Inbox/components/InboxNav'
import { useNewCardItems } from 'apps/Inbox/contexts/cardNotifications'
import InboxCardListHeader from '../InboxCardListHeader'
import InboxCard from './InboxCard'
import useInfiniteCardsQuery from './useInfiniteCardsQuery'

import styles from './styles.module.scss'

export default function InboxCardList() {
  const listRef = useRef()
  const newCardItems = useNewCardItems()
  const [listHeight, setListHeight] = useState(window.innerHeight)

  // safari being safari
  React.useEffect(() => {
    setTimeout(() => setListHeight(window.innerHeight), 300)
  })

  React.useEffect(() => {
    function handleResize() {
      setListHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteCardsQuery()

  const loading = isFetching && !isFetchingNextPage
  const loadingMore = isFetchingNextPage

  const { pages } = data || {}
  const cardList = pages?.map((page) => page.data)?.flat() || []

  const cardListWithEntity = cardList.filter((card) => card.entity)

  const loader = (
    <div className={styles.loadingContainer}>
      <Loading type='triple' role='loading' />
    </div>
  )

  const heightCalc = (ref) => {
    if (ref?.current) {
      const tableHeight =
        listHeight - ref.current.getBoundingClientRect().top - 16
      return `${tableHeight}px`
    }
    return '500px'
  }

  return (
    <>
      <InboxNav loading={loading} />

      <div id='scrollableDiv' className={styles.listContainer} ref={listRef}>
        <InfiniteScroll
          className={styles.list}
          dataLength={cardList.filter((card) => card.entity).length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={loadingMore ? loader : null}
          height={heightCalc(listRef)}
          scrollableTarget='scrollableDiv'
        >
          {loading ? (
            loader
          ) : cardListWithEntity.length === 0 && newCardItems.length === 0 ? (
            <div className={styles.emptyInbox}>
              <SuccessBigIcon color='text-base' />
              <Text type='f3' marginTop='small'>
                <Trans>You're all done.</Trans>
              </Text>
            </div>
          ) : (
            <>
              <InboxCardListHeader newCardItems={newCardItems} />

              {cardListWithEntity.map((card) => (
                <InboxCard card={card} key={card.id} />
              ))}
            </>
          )}
        </InfiniteScroll>
      </div>
    </>
  )
}
