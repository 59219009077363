import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  FieldWrapper,
  FileUploadInput,
  Modal,
  ModalButtons,
  ModalTitle
} from '@fullfabric/alma-mater'

import { useEmailTemplateImageUpload } from 'apps/EmailTemplates/hooks/useEmailTemplateImageUpload'

export const ImageUploadModal = ({
  editor,
  isOpen,
  onClose,
  emailTemplate
}) => {
  const { mutate, isLoading: uploading } = useEmailTemplateImageUpload(
    emailTemplate.id
  )

  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [hasError, setHasError] = React.useState(null)

  const { t } = useTranslation()

  const onChange = useCallback(({ selectedFiles }) => {
    setHasError(false)
    setSelectedFiles(selectedFiles)
  }, [])

  const onCloseInternal = useCallback(() => {
    setSelectedFiles([])
    onClose()
  }, [onClose])

  const uploadFileAndClose = useCallback(() => {
    const selected = editor.getSelected()

    mutate(selectedFiles[0], {
      onError: () => {
        setSelectedFiles([])
        setHasError(true)
      },
      onSuccess: (fileUploaded) => {
        selected.set('src', fileUploaded.url)
        onCloseInternal()
        setSelectedFiles([])
      }
    })
  }, [editor, mutate, onCloseInternal, selectedFiles])

  return (
    <Modal
      isOpen={isOpen}
      size='medium'
      header={
        <ModalTitle
          onClose={uploading ? undefined : onCloseInternal}
          title={t('Image')}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={onCloseInternal} disabled={uploading}>
            {t('Cancel')}
          </Button>
          <Button
            primary
            loading={uploading}
            onClick={uploadFileAndClose}
            disabled={!selectedFiles.length}
          >
            {t('Add image')}
          </Button>
        </ModalButtons>
      }
    >
      <FieldWrapper id='image' label={t('Select image')}>
        <FileUploadInput
          id='single-with-selected-file'
          accept='image/*'
          onChange={onChange}
          hasError={hasError}
          loading={uploading}
          value={selectedFiles}
        />
      </FieldWrapper>
    </Modal>
  )
}
