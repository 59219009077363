import React from 'react'

import BackgroundBlock from '../Background'
import BorderBlock from '../Border'
import PaddingBlock from '../PaddingBlock'

export default function DividerSettings({ editor }) {
  return (
    <>
      <BorderBlock editor={editor} isDetached />
      <BackgroundBlock editor={editor} />
      <PaddingBlock editor={editor} />
    </>
  )
}
