import {
  ApiError,
  getForm,
  submitEventResponse,
  submitForm
} from '@fullfabric/public-api'

import addApplicDiscount from './add-applic-discount'
import addExternalPayerDiscount from './add-external-payer-discount'
import addPaymentPlanDiscount from './add-payment-plan-discount'
import createExternalPayerRequest from './create-external-payer-request'
import createPaymentPlanFee from './create-payment-plan-fee'
import createStripePaymentIntent from './create-stripe-payment-intent'
import getApplic from './get-applic'
import getApplicationAndRegistrationTemplates from './get-application-and-registration-templates'
import getApplicationTemplates from './get-application-templates'
import getApplicationsByProfile from './get-applications-by-profile'
import getCampuses from './get-campuses'
import getClassOf from './get-class-of'
import getClassesState from './get-classes-state'
import getExternalPayerRequest from './get-external-payer-request'
import getOffers from './get-offers'
import getPage from './get-page'
import getPaymentPlan from './get-payment-plan'
import getPaymentPlans from './get-payment-plans'
import getProductsCatalog from './get-products-catalog'
import getProfile from './get-profile'
import getRegistrationTemplates from './get-registration-templates'
import getTemplateChoices from './get-template-choices'
import getThemes from './get-themes'
import getUpcomingEvents from './get-upcoming-events'
import lookupUserEmail from './lookup-user-email'
import removeApplicDiscount from './remove-applic-discount'
import removeExternalPayerDiscount from './remove-external-payer-discount'
import removePaymentPlanDiscount from './remove-payment-plan-discount'
import removePaymentPlanFee from './remove-payment-plan-fee'
import renewExternalPayerRequest from './renew-external-payer-request'
import respondToOffer from './respond-to-offer'
import restartApplication from './restart-application'
import revokeExternalPayerRequest from './revoke-external-payer-request'
import signApplication from './sign-application'
import startApplicationOnBehalf from './start-application-on-behalf'
import submitApplication from './submit-application'
import submitPolicyAcceptance from './submit-policy-acceptance'
import updatePaymentPlanFee from './update-payment-plan-fee'

const api = {
  ApiError,
  getApplic,
  getApplicationTemplates,
  getApplicationsByProfile,
  getCampuses,
  getClassOf,
  getClassesState,
  getForm,
  getOffers,
  getPage,
  getPaymentPlan,
  getPaymentPlans,
  getProductsCatalog,
  getRegistrationTemplates,
  getApplicationAndRegistrationTemplates,
  getTemplateChoices,
  getThemes,
  getUpcomingEvents,
  lookupUserEmail,
  getProfile,
  respondToOffer,
  startApplicationOnBehalf,
  submitEventResponse,
  submitForm,
  submitPolicyAcceptance,
  createStripePaymentIntent,
  addApplicDiscount,
  addPaymentPlanDiscount,
  createExternalPayerRequest,
  createPaymentPlanFee,
  updatePaymentPlanFee,
  removePaymentPlanFee,
  revokeExternalPayerRequest,
  removePaymentPlanDiscount,
  renewExternalPayerRequest,
  removeApplicDiscount,
  submitApplication,
  restartApplication,
  signApplication,
  getExternalPayerRequest,
  addExternalPayerDiscount,
  removeExternalPayerDiscount
}

export default api

export { ApiError, submitEventResponse, submitForm }
