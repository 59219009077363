import React from 'react'

import moment from 'moment-timezone'

import { Text } from '@fullfabric/alma-mater'

import { useLocale } from 'shared/contexts/Locale'
import getLocalizedTimeZoneName from 'shared/utils/get-localised-time-zone-name'

import EventDetailRow from '../event-detail-row'
import ClockIcon from './clock-icon'

import styles from './styles.module.scss'

const EventDate = ({ event }) => {
  const { locale } = useLocale()

  if (!event?.start || !event?.end) {
    return null
  }

  const startMoment = moment(event.start).tz(event.timezoneName).locale(locale)
  const endMoment = moment(event.end).tz(event.timezoneName).locale(locale)

  const startDate = startMoment.format(`dddd, LL`)
  const endDate = endMoment.format(`dddd, LL`)
  const endsOnSameDay = endMoment.isSame(startMoment, 'day')

  const originalTimezone = /time/i.test(event.timezone)
    ? event.timezone
    : `${event.timezone} time`

  const timezone =
    locale.toLowerCase() === 'en-gb'
      ? originalTimezone
      : getLocalizedTimeZoneName(event.timezoneName, locale)

  return (
    <EventDetailRow icon={<ClockIcon />}>
      <Text type='f4' fontColor='text-base-darkest'>
        {`${startDate.charAt(0).toUpperCase() + startDate.slice(1)}`}
      </Text>
      <Text
        type='f5'
        fontColor='text-base-darker'
        data-testid='event-date-time'
      >
        {startMoment.format('LT')}
        {endsOnSameDay ? ` - ${endMoment.format(`LT`)}` : null}
        &nbsp;
        {`(${timezone})`}
      </Text>
      {!endsOnSameDay && (
        <>
          <Text
            fontColor='text-base-darker'
            bgColor='base-light'
            className={styles.separator}
            tag='div'
          >
            to
          </Text>
          <Text type='f4' fontColor='text-base-darkest'>
            {`${endDate.charAt(0).toUpperCase() + endDate.slice(1)}`}
          </Text>
          <Text
            type='f5'
            fontColor='text-base-darker'
            data-testid='event-end-time'
          >
            {endMoment.format(`LT`)}
            &nbsp;
            {`(${timezone})`}
          </Text>
        </>
      )}
    </EventDetailRow>
  )
}

export default EventDate
