import presetMjml from 'grapesjs-mjml'

import { mergeTagPlugin } from './plugins/mergeTagPlugin'
import { textEditorPlugin } from './plugins/textEditorPlugin'

export const getGrapeJsOptionsConfig = (
  colors,
  emailTemplate,
  mergeTags,
  images,
  tipTapEditor
) => ({
  height: 'calc(100vh - 121px)',
  storageManager: false,
  colorPicker: {
    palette: [colors.length > 0 ? colors.map((color) => `#${color}`) : []]
  },
  assetManager: {
    custom: true
  },
  canvas: {
    frameStyle: `
      body { background-color: #fff }
      * ::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.1) }
      * ::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.2) }
      * ::-webkit-scrollbar { width: 10px }
      *[contenteditable=true] { outline: none; }
    `
  },
  styleManager: {
    custom: true
  },
  traitManager: {
    custom: true
  },
  panels: {
    stylePrefix: 'pn-',
    defaults: []
  },
  pluginsOpts: {
    [mergeTagPlugin]: {
      mergeTags
    },
    [presetMjml]: {
      resetDevices: false,
      imagePlaceholderSrc: 'https://placehold.co/600x400/dark/placeholder.png'
    },
    [textEditorPlugin]: {
      editor: tipTapEditor
    }
  }
})
