import React, { useMemo } from 'react'

import PropTypes from 'prop-types'

import AsyncExportModal from 'shared/components/AsyncExportModal'

export default function ContractExportModal({
  billableType,
  contractId,
  sort,
  isOpen,
  onClose
}) {
  const exportOpts = useMemo(
    () => ({
      contract_id: contractId,
      billable_type: billableType,
      sort
    }),
    [contractId, billableType, sort]
  )

  return (
    <AsyncExportModal
      exportOpts={exportOpts}
      exportType='contract'
      isOpen={isOpen}
      title='Export contract events'
      onClose={onClose}
    />
  )
}

ContractExportModal.propTypes = {
  billableType: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    direction: PropTypes.number.isRequired
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
