import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldTraitSize from '../FieldTraitSize'
import PaddingBlock from '../PaddingBlock'
import TypographyBlock from '../Typography'

export default function NavigationLinkSettings({ editor }) {
  const { t } = useTranslation()

  return (
    <>
      <EditorCard>
        <FieldTraitSize editor={editor} label={t('Link address')} name='href' />
      </EditorCard>
      <TypographyBlock editor={editor} />
      <PaddingBlock editor={editor} />
    </>
  )
}
