import React, { createRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import SidebarPanel from '../SidebarPanel'
import {
  ButtonIcon,
  DividerIcon,
  ImageIcon,
  MenuIcon,
  OneColumnIcon,
  SocialIcon,
  SpacerIcon,
  TextIcon,
  ThreeColumnsIcon,
  TwoColumnsIcon
} from './icons'

import styles from './styles.module.scss'

const SocialGroupContent = `
  <mj-social font-size="12px" icon-size="24px" mode="horizontal">
    <mj-social-element border-radius="12px" name="facebook"></mj-social-element>
    <mj-social-element border-radius="12px" name="google"></mj-social-element>
    <mj-social-element border-radius="12px" name="twitter"></mj-social-element>
  </mj-social>
`
export default function ContentPanel({ editor, active }) {
  const { t } = useTranslation()
  const contentBlocksRef = createRef()
  const layoutBlocksRef = createRef()

  useEffect(() => {
    if (!editor) return

    const hashedBlocks = editor.BlockManager.getAll().reduce((acc, block) => {
      acc[block.id] = block
      return acc
    }, {})

    // Set the custom icons
    hashedBlocks['mj-text'].set('media', TextIcon)
    hashedBlocks['mj-text'].set(
      'content',
      '<mj-text line-height="130%">Insert text here</mj-text>'
    )
    hashedBlocks['mj-button'].set('media', ButtonIcon)
    hashedBlocks['mj-image'].set('media', ImageIcon)
    hashedBlocks['mj-social-group'].set('media', SocialIcon)
    hashedBlocks['mj-social-group'].set('content', SocialGroupContent)
    hashedBlocks['mj-navbar'].set('media', MenuIcon)
    hashedBlocks['mj-divider'].set('media', DividerIcon)
    hashedBlocks['mj-spacer'].set('media', SpacerIcon)
    hashedBlocks['mj-1-column'].set('media', OneColumnIcon)
    hashedBlocks['mj-2-columns'].set('media', TwoColumnsIcon)
    hashedBlocks['mj-3-columns'].set('media', ThreeColumnsIcon)

    // Create the renders for the blocks
    const contentBlocks = editor.BlockManager.render(
      [
        hashedBlocks['mj-text'],
        hashedBlocks['mj-button'],
        hashedBlocks['mj-image'],
        hashedBlocks['mj-image'],
        hashedBlocks['mj-social-group'],
        hashedBlocks['mj-navbar'],
        hashedBlocks['mj-divider'],
        hashedBlocks['mj-spacer']
      ],
      { external: true }
    )

    const layoutBlocks = editor.BlockManager.render(
      [
        hashedBlocks['mj-1-column'],
        hashedBlocks['mj-2-columns'],
        hashedBlocks['mj-3-columns']
      ],
      { external: true }
    )

    // Actually append the blocks to the DOM
    contentBlocksRef.current.appendChild(contentBlocks)
    layoutBlocksRef.current.appendChild(layoutBlocks)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  return (
    <SidebarPanel active={active} noPadding>
      <div className={styles.wrapper}>
        <Text type='h5'>{t('Blocks')}</Text>
        <div id='contentBlocks' ref={contentBlocksRef} />
      </div>
      <div className={styles.wrapper}>
        <Text type='h5'>{t('Layout')}</Text>
        <div id='layoutBlocks' ref={layoutBlocksRef} />
      </div>
    </SidebarPanel>
  )
}
