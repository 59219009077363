import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { Badge, Table, Text } from '@fullfabric/alma-mater'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'
import roundNumber from 'shared/utils/roundNumber'

import { IMPORT_TYPES } from 'apps/Imports/utils/constants'
import { STATE_DESCRIPTIONS } from './contants'

const ImportsList = () => {
  const { t } = useTranslation()

  return (
    <AutoloadDataTable
      fixedLayout
      fetchURL='/api/import'
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={paginatedResponseParser}
      emptyMessage={t('No imports found.')}
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': 'created_at',
        'sort[direction]': -1
      }}
      searchable
      searchParamKey='search'
      columns={[
        {
          Header: t('Import'),
          accessor: 'filename',
          type: 'primary',
          width: 350,
          Cell: ({ value, data, row }) => {
            const rowData = data[row.index]

            return (
              <>
                <Table.CellLink
                  href={`/import/${rowData?.id}`}
                  target='_parent'
                  main
                >
                  {value}
                </Table.CellLink>
                <Text tag='div' marginTop='tiny' type='f6'>
                  {rowData.context_type} - {rowData.context_name}
                </Text>
              </>
            )
          }
        },
        {
          Header: t('State'),
          accessor: 'state',
          width: 130,
          Cell: ({ value }) => {
            const courseState =
              STATE_DESCRIPTIONS[value] || STATE_DESCRIPTIONS.init
            return (
              <Badge type={courseState.labelType} className='marginTop-tiny'>
                {courseState.description}
              </Badge>
            )
          }
        },
        {
          Header: t('Stats'),
          accessor: 'number_of_rows',
          width: 150,
          Cell: ({ row, data }) => {
            const rowData = data[row.index]
            const rowCount = rowData.number_of_rows || 0
            const failures = rowData.number_of_failures || 0
            const notices = rowData.number_of_notices || 0
            const successes = rowData.number_of_successes || 0
            const progress =
              rowData.imported_rows &&
              rowData.imported_rows > 0 &&
              rowData.imported_rows !== rowData.number_of_rows &&
              rowData.number_of_rows !== 0
                ? roundNumber(
                    (rowData.imported_rows / rowData.number_of_rows) * 100,
                    1
                  )
                : null

            return (
              <>
                {t('{{rowCount}} rows', { rowCount })}
                <Text tag='div' marginTop='tiny' type='f6'>
                  {successes} . {notices} . {failures}{' '}
                  {progress ? `(${progress}% done)` : ''}
                </Text>
              </>
            )
          }
        },
        {
          Header: t('Import type'),
          width: 200,
          accessor: '_ruby_type',
          Cell: ({ value }) => {
            return (
              <>
                {value === IMPORT_TYPES.PROFILE
                  ? t('Profiles')
                  : value === IMPORT_TYPES.PROGRAMME_AND_CLASS
                  ? t('Programmes & classes')
                  : t('Subjects & courses')}
              </>
            )
          }
        },
        {
          Header: t('Imported by'),
          width: 200,
          accessor: 'created_by_name'
        },
        {
          Header: t('Created at'),
          width: 240,
          accessor: 'created_at',
          Cell: ({ value }) => {
            const date = moment(value)

            return (
              <>
                {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
                <Text tag='div' marginTop='tiny' type='f6'>
                  {date.isValid() && `Created ${date.fromNow()}`}
                </Text>
              </>
            )
          }
        },
        {
          Header: t('Started at'),
          accessor: 'started_at',
          width: 240,
          Cell: ({ value }) => {
            if (!value) return null
            const date = moment(value)

            return (
              <>
                {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
                <Text tag='div' marginTop='tiny' type='f6'>
                  {date.isValid() && `Started ${date.fromNow()}`}
                </Text>
              </>
            )
          }
        },
        {
          Header: t('Imported at'),
          accessor: 'imported_at',
          width: 240,
          Cell: ({ value }) => {
            if (!value) return null
            const date = moment(value)

            return (
              <>
                {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
                <Text tag='div' marginTop='tiny' type='f6'>
                  {date.isValid() && `Imported ${date.fromNow()}`}
                </Text>
              </>
            )
          }
        }
      ]}
    />
  )
}

export default ImportsList
