import React from 'react'
import { useTranslation } from 'react-i18next'

import AlignBlock from '../AlignBlock'
import BackgroundBlock from '../Background'
import BorderBlock from '../Border'
import EditorCard from '../EditorCard'
import FieldTraitSize from '../FieldTraitSize'
import PaddingBlock from '../PaddingBlock'
import SelectImage from '../SelectImage'
import SizeBlock from '../SizeBlock'

export default function ImageStyles({ editor }) {
  const { t } = useTranslation()

  return (
    <>
      <EditorCard>
        <SelectImage editor={editor} name='decorations.background-url' />
        <FieldTraitSize editor={editor} label={t('Alt text')} name='alt' />
        <FieldTraitSize editor={editor} label={t('Link address')} name='href' />
      </EditorCard>

      <SizeBlock editor={editor} />
      <AlignBlock editor={editor} isTextAlign={false} />
      <BackgroundBlock editor={editor} />
      <BorderBlock editor={editor} />
      <PaddingBlock editor={editor} />
    </>
  )
}
