import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import EditorCard from '../EditorCard'
import FieldStyleDropdown from '../FieldStyleDropdown'
import FieldStyleSize from '../FieldStyleSize'

import styles from './styles.module.scss'

export default function BorderBlock({ editor, isDetached }) {
  const { t } = useTranslation()

  const nameDetached = isDetached ? '-detached' : ''
  return (
    <EditorCard>
      <Text type='h5' fontColor='text-base-darkest'>
        {t('Border')}
      </Text>
      <div className={styles.paddingFields}>
        <FieldStyleDropdown
          className={styles.paddingField}
          editor={editor}
          label={t('Style')}
          name={`decorations.border${nameDetached}.border-style`}
        />
        <FieldStyleSize
          className={styles.paddingField}
          editor={editor}
          label={t('Width')}
          name={`decorations.border${nameDetached}.border-width`}
        />
        <FieldStyleSize
          className={styles.paddingField}
          editor={editor}
          isColorPicker
          label={t('Color')}
          name={`decorations.border${nameDetached}.border-color`}
          prefixLabel=''
          inputOptions={{ disableClear: true }}
        />
      </div>
    </EditorCard>
  )
}
