import 'css-chunk:src/apps/ContentPages/components/fees-table/fee-data-row/styles.module.scss';export default {
  "react-app": "react-app",
  "leftmostColumn": "_leftmostColumn_rt7a3_1",
  "amountColumn": "_amountColumn_rt7a3_5",
  "actionsColumn": "_actionsColumn_rt7a3_10",
  "actionsBtn": "_actionsBtn_rt7a3_16",
  "actionsLink": "_actionsLink_rt7a3_21",
  "disabled": "_disabled_rt7a3_21",
  "amountBeforeDiscount": "_amountBeforeDiscount_rt7a3_26",
  "externalPayerData": "_externalPayerData_rt7a3_30"
};