import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldStyleSize from '../FieldStyleSize'

export default function BackgroundBlock({ editor, isContent }) {
  const { t } = useTranslation()

  return (
    <EditorCard>
      <FieldStyleSize
        editor={editor}
        isColorPicker
        label={t('Background color')}
        name={`decorations.${isContent ? '' : 'container-'}background-color`}
        prefixLabel=''
        placeholder='none'
        inputOptions={{ disableClear: true }}
      />
    </EditorCard>
  )
}
