import React from 'react'

import { useLinkedApplications, useTemplate } from '../../template-info-context'
import StartApplication from './start-application'
import StartedApplication from './started-application'

const ApplicationActions = ({ contexts }) => {
  const template = useTemplate()
  const applications = useLinkedApplications() || []

  const submittedApplications = applications.filter(
    (app) => app.state === 'submitted'
  )

  const startedApplication = applications.find(
    (app) => app.state === 'started' || app.state === 'pending_payment'
  )

  const contextIds = new Set(
    (contexts || []).map((c) => c.context_id).filter(Boolean)
  )

  const assignedContextIds = new Set(
    applications
      .flatMap((app) => app?.associated_context_ids || [])
      .filter(Boolean)
  )

  const unassignedContextIds = Array.from(
    contextIds && assignedContextIds
      ? new Set([...contextIds].filter((id) => !assignedContextIds.has(id)))
      : []
  )

  const freeContexts = React.useMemo(
    () =>
      (contexts || []).filter((context) =>
        unassignedContextIds.includes(context.context_id)
      ),
    [contexts, unassignedContextIds]
  )

  // Check if the user can submit a new application:
  // - Either no contexts are loaded (contexts is undefined)
  // - Or there are unassigned context IDs available
  const canSubmitNewApplication = !contexts || unassignedContextIds.length > 0

  return startedApplication ? (
    <StartedApplication
      template={template}
      application={startedApplication}
      contexts={contexts}
    />
  ) : canSubmitNewApplication ? (
    <StartApplication
      hasSubmittedApplications={submittedApplications.length > 0}
      template={template}
      contexts={freeContexts}
    />
  ) : null
}

export default ApplicationActions
