import moment from 'moment'

export const formatDueDate = (date, locale) => {
  return moment(date).locale(locale).format('ll')
}

export const timeToDueDate = (date, locale, t) => {
  const momentDueDate = moment(date).startOf('day').locale(locale)
  const now = moment().locale(locale)

  return t(`Due in {{timeToDueDate}}`, {
    timeToDueDate:
      momentDueDate.diff(now, 'hours') < 24
        ? momentDueDate.toNow(true)
        : momentDueDate.from(now.startOf('day'), true)
  })
}

export const timeFromDueDate = (date, locale, t) => {
  const daysSinceDueDate = moment().diff(date, 'days')

  if (daysSinceDueDate === 0) {
    return t('Due today')
  }

  const timeFromDueDate = moment(date).endOf('day').locale(locale).fromNow(true)

  return t(`{{timeFromDueDate}} overdue`, {
    timeFromDueDate: `${timeFromDueDate
      .charAt(0)
      .toUpperCase()}${timeFromDueDate.slice(1)}`
  })
}
