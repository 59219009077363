import React, { useEffect, useState } from 'react'

import { startCase } from 'lodash'

import { Field } from '@fullfabric/alma-mater'

export default function FieldStyleDropdown({ editor, className, label, name }) {
  const [initialValue, setInitialValue] = useState('')
  const [sectorId, mainPropertyOrPropertyId, propertyId] = name.split('.')
  const sector = editor.StyleManager.getSector(sectorId)
  const mainPropertyOrProperty = sector.getProperty(mainPropertyOrPropertyId)
  const property = propertyId && mainPropertyOrProperty.getProperty(propertyId)

  const getOptions = () => {
    const propertyOrMainProperty = property || mainPropertyOrProperty
    if (propertyOrMainProperty.get('list')) {
      return propertyOrMainProperty.get('list')?.map((option) => ({
        label: startCase(option.name || option.value),
        value: option.value
      }))
    }

    return propertyOrMainProperty
      .get('options')
      .map((option) => ({ label: startCase(option.id), value: option.id }))
  }

  useEffect(() => {
    const updateValues = () => {
      setInitialValue((property || mainPropertyOrProperty).getValue())
    }

    updateValues() // Set the initial values
    editor.on('style:custom', updateValues)

    return () => editor.off('style:custom', updateValues)
  }, [
    sector,
    mainPropertyOrPropertyId,
    propertyId,
    property,
    mainPropertyOrProperty,
    editor
  ])

  return (
    <Field
      id={propertyId || mainPropertyOrPropertyId}
      type='dropdown'
      value={initialValue || ''}
      className={className}
      label={label}
      onChange={(_, value) => {
        ;(property || mainPropertyOrProperty).upValue(value)
      }}
      inputOptions={{
        isSearchable: false,
        isClearable: false,
        options: getOptions()
      }}
    />
  )
}
