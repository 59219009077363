import React, { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { ArrowLeftIcon, PageHead, Tabs } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'

import getContract from 'apps/Billing/api/getContract'
import { CONTRACTS_TABLE_ROUTE } from 'apps/Billing/components/AppRoutes'
import { GET_CONTRACT_QUERY_KEY } from 'apps/Billing/constants/queryParts'
import getContractName from 'apps/Billing/utils/getContractName'
import ContractEventGroupsTable from './ContractEventGroupsTable'
import ExportButton from './ExportButton'
import SettingsButton from './SettingsButton'

export default function ContractPage({ contractId }) {
  const { data: contract, isLoading } = useQuery(
    [GET_CONTRACT_QUERY_KEY, contractId],
    () => getContract(contractId)
  )

  const {
    eventGroupsSort,
    eventGroupsType,
    handleSortChanged,
    handleTypeChanged
  } = useEventGroupParams(contractId)

  const tabContent = (
    <ContractEventGroupsTable
      contractId={contractId}
      billableType={eventGroupsType}
      onSortChanged={handleSortChanged}
    />
  )

  return (
    <TablePageLayout>
      <PageHead
        noBorder
        title={
          <span data-testid='page-title'>
            {isLoading ? 'Loading...' : getContractName(contract)}
          </span>
        }
        breadcrumbs={[
          {
            label: 'Back to billing',
            icon: ArrowLeftIcon,
            to: CONTRACTS_TABLE_ROUTE
          }
        ]}
        action={
          <>
            <ExportButton
              billableType={eventGroupsType}
              contractId={contractId}
              sort={eventGroupsSort}
            />
            <SettingsButton contractId={contractId} />
          </>
        }
      />

      <Tabs defaultActiveTab={eventGroupsType} onTabChange={handleTypeChanged}>
        <Tabs.Panel id='applic' label='Applications'>
          {tabContent}
        </Tabs.Panel>

        <Tabs.Panel id='engagement' label='Engagements'>
          {tabContent}
        </Tabs.Panel>

        <Tabs.Panel id='registration' label='Registrations'>
          {tabContent}
        </Tabs.Panel>
      </Tabs>
    </TablePageLayout>
  )
}

function useEventGroupParams(contractId) {
  const [eventGroupsSort, setEventGroupsSort] = useState(null)

  const match = useRouteMatch(`/billing/${contractId}/events/:billableType`)
  const history = useHistory()
  const eventGroupsType = match?.params?.billableType || 'applic'

  const handleSortChanged = useCallback((tableSort) => {
    setEventGroupsSort(destructureSort(tableSort))
  }, [])

  const handleTypeChanged = useCallback(
    (type) => history.replace(`/billing/${contractId}/events/${type}`),
    [contractId, history]
  )

  return {
    eventGroupsSort,
    eventGroupsType,
    handleSortChanged,
    handleTypeChanged
  }
}

function destructureSort(sort) {
  if (!sort) return null

  const { 'sort[field]': field, 'sort[direction]': direction } = sort
  return { field, direction }
}
