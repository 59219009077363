import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { Field, Modal, ModalTitle } from '@fullfabric/alma-mater'

import useExportCount from 'shared/hooks/exports/useExportCount'
import useExportCreate from 'shared/hooks/exports/useExportCreate'

import ExportStatusCallout from './AsyncExportModal/ExportStatusCallout'
import Footer from './AsyncExportModal/Footer'

import styles from './AsyncExportModal/styles.module.scss'

const FORMAT_OPTIONS = [
  { value: 'csv', label: 'CSV' },
  { value: 'xlsx', label: 'Excel' }
]

export default function AsyncExportModal({
  exportOpts,
  exportType,
  isOpen,
  title,
  onClose
}) {
  const { exportCount, exportCountReady } = useExportCount({
    type: exportType,
    opts: exportOpts
  })

  const {
    createExport,
    creating: exporting,
    error: exportCreateError,
    export: xport,
    reset
  } = useExportCreate()

  const [format, setFormat] = useState('csv')

  return (
    <Modal
      isOpen={isOpen}
      id='export-modal'
      footer={
        <Footer
          exporting={exporting}
          ready={exportCountReady}
          showExport={!xport}
          onClose={onClose}
          onExport={() =>
            createExport({ format, opts: exportOpts, type: exportType })
          }
          onReset={reset}
        />
      }
      header={<ModalTitle title={title} onClose={onClose} />}
      size='medium'
    >
      <ExportStatusCallout
        xport={xport}
        exportCreateError={exportCreateError}
        exportCount={exportCount}
        exportCountReady={exportCountReady}
      />

      {!xport && (
        <form className={styles.form}>
          <Field
            id='export-format-select'
            disabled={exporting}
            type='dropdown'
            label='Export format'
            inputOptions={{ inModal: true }}
            options={FORMAT_OPTIONS}
            value={format}
            onChange={(_id, format) => setFormat(format)}
          />
        </form>
      )}
    </Modal>
  )
}

AsyncExportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  exportOpts: PropTypes.object.isRequired,
  exportType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}
