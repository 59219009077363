import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  Text
} from '@fullfabric/alma-mater'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import AssigneeField from './AssigneeField'
import EvaluationTemplateField from './EvaluationTemplateField'
import ProductChoiceField from './ProductChoiceField'
import useSubmitEvaluationCreation from './useSubmitEvaluationCreation'

const EVAL_TEMPLATE_FIELD_NAME = 'evaluation_template_id'
const PRODUCT_CHOICE_FIELD_NAME = 'choice'
const ASSIGNEE_FIELD_NAME = 'assigned_to_id'

function CreationModal({ isOpen, handleClose, onSubmitted }) {
  const { t } = useTranslation()
  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const assignEvaluationEnabled = useSettingByName(
    'modules.evaluations.features.evaluation_assignment.enable'
  )

  const { onSubmit, isSubmitting, hasServerError } =
    useSubmitEvaluationCreation({
      onSubmitted: () => {
        onSubmitted()
        handleClose()
      }
    })

  return (
    <Modal
      role='dialog'
      aria-labelledby='creation-modal-title'
      isOpen={isOpen}
      size='medium'
      header={
        <ModalTitle
          title={
            <span id='creation-modal-title'>{t('Create new evaluation')}</span>
          }
          onClose={handleClose}
        />
      }
      footer={
        <ModalButtons>
          <Button onClick={handleClose} disabled={isSubmitting}>
            {t('Cancel')}
          </Button>
          <Button
            primary
            type='submit'
            form='creation-modal-form'
            onClick={handleSubmit(onSubmit)}
            loading={isSubmitting}
          >
            {t('Create evaluation')}
          </Button>
        </ModalButtons>
      }
    >
      <FormProvider {...formMethods}>
        <form id='creation-modal-form'>
          <EvaluationTemplateField name={EVAL_TEMPLATE_FIELD_NAME} />
          <ProductChoiceField name={PRODUCT_CHOICE_FIELD_NAME} />
          {assignEvaluationEnabled ? (
            <AssigneeField
              name={ASSIGNEE_FIELD_NAME}
              evalTemplateFieldName={EVAL_TEMPLATE_FIELD_NAME}
            />
          ) : null}
        </form>
      </FormProvider>
      {hasServerError ? (
        <Text fontColor='text-alert' type='f5' marginTop='default'>
          {t(
            'There was an error, please try again in a few seconds. If the issue persists, please contact us.'
          )}
        </Text>
      ) : null}
    </Modal>
  )
}

export default CreationModal
