import 'css-chunk:src/apps/ContentPages/components/fees-table/styles.module.scss';export default {
  "react-app": "react-app",
  "table": "_table_u5757_1",
  "header": "_header_u5757_18",
  "headerItem": "_headerItem_u5757_23",
  "mobile": "_mobile_u5757_32",
  "row": "_row_u5757_36",
  "borderless": "_borderless_u5757_41",
  "tableData": "_tableData_u5757_45",
  "leftmostColumn": "_leftmostColumn_u5757_54",
  "actionsColumn": "_actionsColumn_u5757_58",
  "bigger": "_bigger_u5757_61",
  "amountColumn": "_amountColumn_u5757_65",
  "arrowIconColumn": "_arrowIconColumn_u5757_70",
  "placeholder": "_placeholder_u5757_73"
};