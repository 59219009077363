import { useMutation } from 'react-query'

import { utils } from '@fullfabric/public-api'

export const useEmailTemplateImageUpload = (id) => {
  return useMutation({
    mutationFn: async (file) => {
      const form = new FormData()
      form.append('file', file)
      const response = await fetch(`/api/email_templates/${id}/uploads`, {
        method: 'POST',
        body: form,
        credentials: 'same-origin'
      })
      return utils.checkResponse(response)
    },
    enabled: Boolean(id)
  })
}
