import React from 'react'
import { useTranslation } from 'react-i18next'

import AlignBlock from '../AlignBlock'
import BackgroundBlock from '../Background'
import BorderBlock from '../Border'
import BorderRadiusBlock from '../BorderRadius'
import EditorCard from '../EditorCard'
import FieldTraitSize from '../FieldTraitSize'
import PaddingBlock from '../PaddingBlock'

export default function SectionSettings({ editor }) {
  const { t } = useTranslation()

  return (
    <>
      <EditorCard>
        <FieldTraitSize
          editor={editor}
          label={t('Full width')}
          name='full-width'
          trueValue='full-width'
          type='checkbox'
        />
      </EditorCard>

      <BackgroundBlock editor={editor} isContent />
      <AlignBlock editor={editor} />
      <BorderRadiusBlock editor={editor} />
      <BorderBlock editor={editor} />
      <PaddingBlock editor={editor} />
    </>
  )
}
