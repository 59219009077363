import React, { useEffect, useState } from 'react'

import { Field } from '@fullfabric/alma-mater'

export default function FieldTraitDropdown({ editor, className, label, name }) {
  const [initialValue, setInitialValue] = useState('')
  const [property, setProperty] = useState(null)

  useEffect(() => {
    const updateValues = () => {
      const property = editor.TraitManager.getTraits().find(
        (trait) => trait.id === name
      )

      if (!property) return

      setProperty(property)
      setInitialValue(property.getValue({ useType: true }))
    }

    updateValues() // Set the initial values

    editor.on('trait:custom', updateValues)
    return () => editor.off('trait:custom', updateValues)
  }, [editor, name])

  return (
    <Field
      type='dropdown'
      value={initialValue || ''}
      className={className}
      label={label}
      onChange={(_, value) => {
        property.setValue(value)
      }}
      inputOptions={{
        small: true,
        isSearchable: false,
        isClearable: false,
        options:
          property?.get('options').map((option) => ({
            label: option.name,
            value: option.value
          })) || []
      }}
    />
  )
}
