import React from 'react'

import StatusBadge from './StatusBadge'

import styles from './styles.module.scss'

export default function StatusCell({ row, data }) {
  const isBillable = data[row.index].billable
  const isDeleted = data[row.index].deleted
  const statuses = [
    isBillable ? 'billable' : 'non-billable',
    isDeleted ? 'deleted' : ''
  ].filter((s) => !!s)

  return (
    <div className={styles.badgesAligner}>
      {statuses.map((status, index) => (
        <div key={index} className={styles.badgeContainer}>
          <StatusBadge status={status} />

          {index < statuses.length - 1 && (
            <div className={styles.badgeSpacing} />
          )}
        </div>
      ))}
    </div>
  )
}
