import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

export const useTenantColors = () => {
  const colors = useSettingByName(
    'modules.core.submodules.email_templates.themes.colors'
  )

  if (!colors) return []

  return colors.map((color) => (color.match(/^#/) ? color : `#${color}`))
}
