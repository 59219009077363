import React from 'react'
import { Trans } from 'react-i18next'

import AppLayout from 'apps/Authentication/components/AppLayout'
import AuthContentLayout from 'apps/Authentication/components/AuthContentLayout'
import ResetPasswordForm from 'apps/Authentication/components/ResetPasswordForm'
import { LOGIN_ROUTE } from 'apps/Authentication/constants/routes'

export default function ForgotPasswordPage() {
  return (
    <AppLayout>
      <AuthContentLayout>
        <AuthContentLayout.Body>
          <ResetPasswordForm />
        </AuthContentLayout.Body>

        <AuthContentLayout.Footer
          question={<Trans>Already have an account?</Trans>}
          linkTo={LOGIN_ROUTE}
          linkText={<Trans>Sign in</Trans>}
        />
      </AuthContentLayout>
    </AppLayout>
  )
}
