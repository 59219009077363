import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { isNil, noop } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Badge } from '@fullfabric/alma-mater'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'

import BackfillBillableStatusModal from './ContractEventGroupsTable/BackfillBillableStatusModal'
import EventGroupActionsCell from './ContractEventGroupsTable/EventGroupActionsCell'
import EventGroupEventsModal from './ContractEventGroupsTable/EventGroupEventsModal'
import StatusCell from './ContractEventGroupsTable/StatusCell'

export default function ContractEventGroupsTable({
  contractId,
  billableType,
  onSortChanged
}) {
  const { t } = useTranslation()
  const [showingEventGroupEventsId, setShowingEventGroupEventsId] =
    useState(null)

  const [
    { profileId: showingBackfillBillableStatusModalProfileId, makeBillable },
    setShowingBackfillBillableStatusModalProfile
  ] = useState({})

  const showEventGroupEvents = useCallback(
    (eventGroupId) => setShowingEventGroupEventsId(eventGroupId),
    [setShowingEventGroupEventsId]
  )

  const showBackfillBillableStatusModal = useCallback(
    (profileId, { makeBillable }) =>
      setShowingBackfillBillableStatusModalProfile({
        profileId,
        makeBillable
      }),
    [setShowingBackfillBillableStatusModalProfile]
  )

  const fetchParams = useMemo(
    () => ({
      include: { profile_is_billable: true, deleted: true },
      billable_type: billableType
    }),
    [billableType]
  )

  const columns = useEventGroupsColumns(
    billableType,
    showEventGroupEvents,
    showBackfillBillableStatusModal
  )

  return (
    <>
      <AutoloadDataTable
        fetchURL={`/billing/api/contracts/${contractId}/event_groups`}
        fetchParams={fetchParams}
        loadMoreProps={{
          sizeKey: 'limit',
          offsetKey: 'offset'
        }}
        fetchResponseParser={paginatedResponseParser}
        columns={columns}
        emptyMessage={t('No data')}
        sortable
        sortParams={{
          sortKey: 'sort[field]',
          directionKey: 'sort[direction]',
          ascValue: 1,
          descValue: -1
        }}
        initialSort={{
          'sort[field]': 'first_event_at',
          'sort[direction]': -1
        }}
        onSortChanged={onSortChanged}
      />

      <EventGroupEventsModal
        billableType={billableType}
        contractId={contractId}
        eventGroupId={showingEventGroupEventsId}
        onClose={() => setShowingEventGroupEventsId(null)}
      />

      <BackfillBillableStatusModal
        contractId={contractId}
        profileId={showingBackfillBillableStatusModalProfileId}
        makeBillable={makeBillable}
        onClose={() => setShowingBackfillBillableStatusModalProfile({})}
      />
    </>
  )
}

ContractEventGroupsTable.propTypes = {
  contractId: PropTypes.string.isRequired,
  billableType: PropTypes.string.isRequired,
  onSortChanged: PropTypes.func
}

function useEventGroupsColumns(
  billableType,
  handleShowGroupEvents,
  handleChangeProfileBillableStatus
) {
  const { t } = useTranslation()

  return useMemo(() => {
    const cols = [
      {
        Header: t('Name'),
        accessor: 'profile_name',
        disableSortBy: true,
        linkTo: ({ row, data, _value }) => ({
          href: `/profiles/${data[row.index].profile_id}`,
          target: '_parent'
        })
      },
      {
        Header: t('Profile type'),
        accessor: 'profile_is_billable',
        type: 'text',
        disableSortBy: true,
        getTitle: ({ value }) => (value ? t('Billable') : t('Non-billable')),
        Cell: ({ value }) => {
          if (value || isNil(value)) return null
          return <Badge>{t('Non-billable')}</Badge>
        }
      }
    ]

    if (billableType === 'applic') {
      cols.push({
        Header: t('Application name'),
        accessor: 'applic_template_name',
        type: 'text',
        disableSortBy: true,
        getTitle: noop
      })
    }

    cols.push(
      {
        Header: firstEventHeaderLabel(billableType),
        accessor: 'first_event_at',
        type: 'text',
        Cell: ({ value }) => moment(value).format('DD MMM YYYY h:mm A'),
        getTitle: noop
      },
      {
        Header: t('Status'),
        Cell: StatusCell,
        disableSortBy: true
      },
      {
        Header: eventCountHeaderLabel(billableType),
        accessor: 'event_count',
        type: 'text',
        getTitle: noop,
        disableSortBy: true
      },
      {
        Header: '',
        id: 'actions-col',
        Cell: (props) => (
          <EventGroupActionsCell
            {...props}
            billableType={billableType}
            onShowEventGroupEvents={handleShowGroupEvents}
            onChangeProfileBillableStatus={handleChangeProfileBillableStatus}
          />
        ),
        disableSortBy: true,
        width: 60
      }
    )

    return cols
  }, [
    t,
    handleShowGroupEvents,
    handleChangeProfileBillableStatus,
    billableType
  ])
}

function firstEventHeaderLabel(billableType) {
  if (billableType === 'applic') return <Trans>First submitted on</Trans>
  if (billableType === 'engagement') return <Trans>First engaged on</Trans>
  return <Trans>First registered on</Trans>
}

function eventCountHeaderLabel(billableType) {
  if (billableType === 'applic') return <Trans>Submissions</Trans>
  if (billableType === 'engagement') return <Trans>Engagements</Trans>
  return <Trans>Registrations</Trans>
}
