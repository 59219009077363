import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { useCurrentUser, useSettings } from '@fullfabric/authorization-officer'

import { LocaleProvider } from 'shared/contexts/Locale'
import getInitialLocale from 'shared/utils/getInitialLocale'

export default function LocalizedWrapper({ children }) {
  const { i18n } = useTranslation()
  const user = useCurrentUser()
  const settings = useSettings()
  const locales = settings.hash.locales
  const initialLocale = getInitialLocale(user, locales)

  useEffect(() => {
    if (i18n.language !== initialLocale) {
      i18n.changeLanguage(initialLocale)
    }
  }, [i18n, initialLocale])

  useEffect(() => {
    moment.locale(initialLocale)
  }, [initialLocale])

  return (
    <LocaleProvider initialLocale={initialLocale} locales={locales}>
      {children}
    </LocaleProvider>
  )
}
