import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import moment from 'moment'

import { ActionList, Badge, MoreIcon, Text } from '@fullfabric/alma-mater'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'

import DeleteCourseModal from 'apps/Courses/containers/DeleteCourseModal'
import STATE_DESCRIPTIONS from 'apps/Courses/utils/courseStateDescriptions'
import {
  EDIT_COURSE_ROUTE,
  EDIT_SUBJECT_ROUTE
} from 'apps/Courses/utils/routeConstants'
import { isNumber } from 'apps/Courses/utils/validationHelper'

export default function CourseList({ campusesOptions, onOpenDeleteModal }) {
  const { t } = useTranslation()

  const tableColumns = [
    {
      Header: t('Subject name'),
      accessor: 'subject_name',
      linkTo: ({ row, data }) => {
        return {
          href: EDIT_SUBJECT_ROUTE.replace(':id', data[row.index].course_id),
          target: '_parent'
        }
      },
      width: 350
    },
    {
      Header: t('Course name'),
      accessor: 'name',
      type: 'primary',
      linkTo: ({ row, data }) => {
        return {
          to: EDIT_COURSE_ROUTE.replace(':id', data[row.index].id)
        }
      },
      width: 250,
      Cell: ({ value }) => {
        return value || t('Not defined')
      }
    },
    {
      Header: t('State'),
      accessor: 'state',
      width: 180,
      Cell: ({ value, row, data }) => {
        const courseState = STATE_DESCRIPTIONS[value] || STATE_DESCRIPTIONS.init
        return (
          <>
            <Badge type={courseState.labelType} className='marginTop-tiny'>
              {courseState.description}
            </Badge>
            {data[row.index]?.archived && (
              <Text small marginTop='small'>
                <Trans>Archived</Trans>
              </Text>
            )}
          </>
        )
      }
    },
    {
      Header: t('Price'),
      accessor: 'price_amount',
      Cell: ({ value, row, data }) => {
        return isNumber(value)
          ? `${value} ${data[row.index].price_currency || ''}`
          : ''
      }
    },
    {
      Header: t('Owner'),
      accessor: 'owner_name',
      Cell: ({ value }) => value || '-'
    },
    {
      Header: t('Applications open on'),
      accessor: 'opens_on',
      width: 270,
      Cell: ({ value }) => {
        if (!value) return ''

        const date = moment(value)
        const now = moment()

        return (
          <>
            {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
            <Text tag='div' marginTop='tiny' type='f6'>
              {date.isValid() &&
                (date.isAfter(now)
                  ? `Opens ${date.fromNow()}`
                  : `Opened ${date.fromNow()}`)}
            </Text>
          </>
        )
      }
    },
    {
      Header: t('Applications close on'),
      accessor: 'closes_on',
      width: 270,
      Cell: ({ value }) => {
        if (!value) return ''

        const date = moment(value)
        const now = moment()

        return (
          <>
            {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
            <Text tag='div' marginTop='tiny' type='f6'>
              {date.isValid() &&
                (date.isAfter(now)
                  ? `Closes ${date.fromNow()}`
                  : `Closed ${date.fromNow()}`)}
            </Text>
          </>
        )
      }
    },
    {
      Header: t('Class starts on'),
      accessor: 'starts_on',
      width: 270,
      Cell: ({ value }) => {
        if (!value) return ''

        const date = moment(value)
        const now = moment()

        return (
          <>
            {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
            <Text tag='div' marginTop='tiny' type='f6'>
              {date.isValid() &&
                (date.isAfter(now)
                  ? `Starts ${date.fromNow()}`
                  : `Started ${date.fromNow()}`)}
            </Text>
          </>
        )
      }
    },
    {
      Header: t('Class ends on'),
      accessor: 'ends_on',
      width: 270,
      Cell: ({ value }) => {
        if (!value) return ''

        const date = moment(value)
        const now = moment()

        return (
          <>
            {date.isValid() && date.format('dddd MMM D, Y [at] HH:mm')}
            <Text tag='div' marginTop='tiny' type='f6'>
              {date.isValid() &&
                (date.isAfter(now)
                  ? `Ends ${date.fromNow()}`
                  : `Ended ${date.fromNow()}`)}
            </Text>
          </>
        )
      }
    },
    {
      Header: '',
      accessor: 'id',
      width: 50,
      disableSortBy: true,
      Cell: ({ row, data }) => {
        return (
          <ActionList
            iconButtonProps={{ plain: true }}
            iconButton={<MoreIcon />}
            alignEnd
          >
            <ActionList.Option
              negative
              onClick={(evt) => {
                evt.stopPropagation()
                onOpenDeleteModal(data[row.index])
              }}
            >
              Delete course
            </ActionList.Option>
          </ActionList>
        )
      }
    }
  ]

  if (campusesOptions && campusesOptions.length > 0) {
    tableColumns.splice(2, 0, {
      Header: t('Campuses'),
      accessor: 'campuses_names',
      type: 'primary',
      width: 250,
      Cell: ({ value }) => (value ? value.join(', ') : '')
    })
  }

  return (
    <>
      <AutoloadDataTable
        fetchURL='/api/tabular_data/courses'
        fixedLayout
        loadMoreProps={{
          sizeKey: 'limit',
          offsetKey: 'offset'
        }}
        fetchResponseParser={paginatedResponseParser}
        emptyMessage={t('No courses found.')}
        // onRowClick={({ rowData }) => {
        //   onCourseClick(rowData.id);
        // }}
        sortable
        sortParams={{
          sortKey: 'sort[field]',
          directionKey: 'sort[direction]',
          ascValue: 1,
          descValue: -1
        }}
        initialSort={{
          'sort[field]': 'starts_on',
          'sort[direction]': -1
        }}
        searchable
        searchParamKey='search'
        columns={tableColumns}
      />
      <DeleteCourseModal />
    </>
  )
}
