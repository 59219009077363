import { utils } from '@fullfabric/public-api'

const getPaymentPlan = async (profileId, paymentPlanId) => {
  const response = await fetch(
    `/book_keeper/api/profiles/${profileId}/plans/${paymentPlanId}`,
    { credentials: 'same-origin' }
  )
  return await utils.checkResponse(response)
}

export default getPaymentPlan
