import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useMutation } from 'react-query'

import PropTypes from 'prop-types'

import {
  Button,
  Modal,
  ModalButtons,
  ModalTitle,
  RadioInput,
  Text
} from '@fullfabric/alma-mater'

import { usePagedQueriesUpdater } from 'shared/hooks/usePagedQueriesUpdater'

import makeProfileBillable from 'apps/Billing/api/makeProfileBillable'
import makeProfileUnbillable from 'apps/Billing/api/makeProfileUnbillable'
import { getEventGroupsUnderContractQueryKeyFilter } from 'apps/Billing/constants/queryParts'

import styles from './BackfillBillableStatusModal/styles.module.scss'

/**
 * Displays a modal that allows the user to optionally backfill an update to the
 * Profile's billable status to all their past events.
 */
export default function BackfillBillableStatusModal({
  contractId,
  profileId,
  makeBillable,
  onClose
}) {
  const [backfill, setBackfill] = useState(false)

  const [makeProfileBillableMutation, makeProfileUnbillableMutation] =
    useProfileBillableMutation(contractId, onClose)

  return (
    <Modal
      isOpen={!!profileId}
      onClose={onClose}
      header={<ModalTitle title={<Trans>Update billable status</Trans>} />}
      footer={
        <ModalButtons>
          <Button onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>

          <Button
            primary
            onClick={() =>
              makeBillable
                ? makeProfileBillableMutation.mutate({ profileId, backfill })
                : makeProfileUnbillableMutation.mutate({ profileId, backfill })
            }
            role='change'
          >
            <Trans>Apply</Trans>
          </Button>
        </ModalButtons>
      }
      size='large'
    >
      <div className={styles.backfillBillableStatusModal}>
        <div>
          <Text type='h5' fontColor='text-base-darkest'>
            <Trans>Backfill billable status</Trans>
          </Text>

          <Text type='f5'>
            <Trans>
              Would you like to apply this change to all past events for this
              profile?
            </Trans>
          </Text>
        </div>

        <div>
          <RadioInput
            id='backfill-no'
            name='backfill'
            checked={!backfill}
            onChange={() => setBackfill(false)}
            label={
              <Text>
                <Trans>No, do not update existing events</Trans>
              </Text>
            }
          />

          <RadioInput
            id='backfill-yes'
            name='backfill'
            checked={backfill}
            onChange={() => setBackfill(true)}
            label={
              <Text>
                <Trans>
                  Yes, update the billable status of existing events
                </Trans>
              </Text>
            }
          />
        </div>
      </div>
    </Modal>
  )
}

BackfillBillableStatusModal.propTypes = {
  contractId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
  makeBillable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

function useProfileBillableMutation(contractId, onSuccess) {
  const updatePages = usePagedQueriesUpdater(
    getEventGroupsUnderContractQueryKeyFilter(contractId)
  )

  const updateEventGroups = (editedProfile) => {
    updatePages((page) => {
      page.data.forEach((eventGroup) => {
        if (editedProfile.id === eventGroup.profile_id) {
          eventGroup.profile_is_billable = editedProfile.billable
        }
      })
    })
  }

  const handleSuccess = (editedProfile) => {
    updateEventGroups(editedProfile)
    onSuccess?.(editedProfile)
  }

  const makeProfileBillableMutation = useMutation(
    ({ profileId, backfill }) => makeProfileBillable(profileId, { backfill }),
    { onSuccess: handleSuccess }
  )

  const makeProfileUnbillableMutation = useMutation(
    ({ profileId, backfill }) => makeProfileUnbillable(profileId, { backfill }),
    { onSuccess: handleSuccess }
  )

  return [makeProfileBillableMutation, makeProfileUnbillableMutation]
}
