import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldStyleSize from '../FieldStyleSize'

import styles from './styles.module.scss'

export default function BorderRadiusBlock({ editor }) {
  const { t } = useTranslation()

  return (
    <EditorCard title={t('Corner radius')}>
      <div className={styles.paddingFields}>
        {['top-left', 'top-right', 'bottom-left', 'bottom-right'].map(
          (direction) => (
            <FieldStyleSize
              key={direction}
              className={styles.paddingField}
              editor={editor}
              label={t(`border-radius-${direction}`)}
              name={`decorations.border-radius.border-${direction}-radius`}
            />
          )
        )}
      </div>
    </EditorCard>
  )
}
