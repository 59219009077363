const getLocalizedTimeZoneName = (timezone, locale) => {
  try {
    const formatter = new Intl.DateTimeFormat(locale, {
      timeZone: timezone,
      timeZoneName: 'long'
    })

    return (
      formatter
        .formatToParts(new Date())
        .find((part) => part.type === 'timeZoneName')?.value || timezone
    )
  } catch (error) {
    return timezone // Fallback to IANA if formatting fails
  }
}

export default getLocalizedTimeZoneName
