import React from 'react'
import { useTranslation } from 'react-i18next'

import EditorCard from '../EditorCard'
import FieldStyleSize from '../FieldStyleSize'

import styles from './styles.module.scss'

export default function PaddingBlock({ editor }) {
  const { t } = useTranslation()

  return (
    <EditorCard title={t('Padding')}>
      <div className={styles.paddingFields}>
        {['top', 'right', 'bottom', 'left'].map((direction) => (
          <FieldStyleSize
            key={direction}
            className={styles.paddingField}
            editor={editor}
            label={t(`padding-${direction}`)}
            name={`dimension.padding.padding-${direction}`}
          />
        ))}
      </div>
    </EditorCard>
  )
}
