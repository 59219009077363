import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function SelectImage({ editor }) {
  const { t } = useTranslation()

  const onClick = () => {
    editor.Assets.open({
      select: (asset, complete) => {
        editor.getSelected().set({ src: asset.getSrc() })
        complete && editor.Assets.close()
      },
      types: ['image'],
      accept: 'image/*'
    })
  }

  return (
    <div className={styles.container}>
      <Text type='h5' fontColor='text-base-darkest'>
        {t('Image')}
      </Text>

      <Button size='small' onClick={onClick}>
        {t('Select Image')}
      </Button>
    </div>
  )
}
