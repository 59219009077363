export const CONTRACTS_TABLE_QUERY_KEY_PART = [
  'tableDataFetch',
  '/billing/api/contracts'
]

export const getEventsUnderEventGroupQueryKeyFilter = (eventGroupId) => [
  'tableDataFetch',
  `/billing/api/event_groups/${eventGroupId}/events`
]

export const getEventGroupsUnderContractQueryKeyFilter = (contractId) => [
  'tableDataFetch',
  `/billing/api/contracts/${contractId}/event_groups`
]

export const GET_CONTRACT_QUERY_KEY = 'getContract'
