import React from 'react'

import BackgroundBlock from '../Background'
import PaddingBlock from '../PaddingBlock'

export default function TextStyles({ editor }) {
  return (
    <>
      <PaddingBlock editor={editor} />
      <BackgroundBlock
        editor={editor}
        isContent={editor.getSelected()?.get('type') === 'text'}
      />
    </>
  )
}
