import { utils } from '@fullfabric/public-api'

const updatePaymentPlanFee = async ({
  profileId,
  paymentPlanId,
  feeId,
  data
}) => {
  const response = await fetch(
    `/book_keeper/api/profiles/${profileId}/plan/${paymentPlanId}/fees/${feeId}`,
    {
      credentials: 'same-origin',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
  )

  return await utils.checkResponse(response)
}

export default updatePaymentPlanFee
