import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { Field } from '@fullfabric/alma-mater'

import getCoursesForSubject from 'shared/api/affairs/getCoursesForSubject'

import {
  useOnChangeSubjectEditionData,
  useSubjectEditionData
} from 'apps/StudyPlans/contexts/SubjectEditionData'
import getCourseName from 'apps/StudyPlans/utils/getCourseName'

const CoursesField = () => {
  const subjectData = useSubjectEditionData()
  const onChange = useOnChangeSubjectEditionData()
  const selectedSubjectId = subjectData.course_id

  const { data: subjectsList, isLoading } = useQuery(
    ['subjects', selectedSubjectId],
    async () => {
      return getCoursesForSubject(selectedSubjectId)
    },
    {
      enabled: !!selectedSubjectId,
      staleTime: Infinity
    }
  )

  const selectedSubject = !isLoading && subjectsList

  const allCourses = selectedSubject?.units || []

  return (
    <Field
      id='unit_ids'
      type='tag'
      label={<Trans>Courses</Trans>}
      onChange={onChange}
      value={subjectData.unit_ids || []}
      inputOptions={{
        disableCreate: true,
        disabled: !selectedSubjectId,
        placeholder: selectedSubjectId ? (
          <Trans>All courses, including upcoming courses</Trans>
        ) : null,
        options: allCourses.map((c) => ({
          label: getCourseName(c),
          value: c.id
        }))
      }}
    />
  )
}

export default CoursesField
