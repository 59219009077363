import React from 'react'

import AlignBlock from '../AlignBlock'
import BackgroundBlock from '../Background'
import PaddingBlock from '../PaddingBlock'
import SizeBlock from '../SizeBlock'

export default function SocialSettings({ editor }) {
  return (
    <>
      <SizeBlock editor={editor} />
      <AlignBlock editor={editor} />
      <PaddingBlock editor={editor} />
      <BackgroundBlock editor={editor} isContainer />
    </>
  )
}
