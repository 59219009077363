import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { capitalize, noop } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import { usePermission } from '@fullfabric/authorization-officer'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import paginatedResponseParser from 'shared/utils/paginatedResponseParser'

import StatusCell from '../StatusCell'
import EventActionsCell from './EventGroupEventsTable/EventActionsCell'

export default function EventGroupEventsTable({
  billableType,
  contractId,
  eventGroupId
}) {
  const hasBillableChangePermission = usePermission('billing.billable_change')

  const columns = useMemo(() => {
    const columns = []

    if (billableType === 'engagement') {
      columns.push({
        Header: <Trans>Engagement</Trans>,
        accessor: 'type',
        type: 'text',
        Cell: EngagementTypeCell,
        getTitle: noop
      })
    } else if (billableType === 'registration') {
      columns.push({
        Header: <Trans>Course name</Trans>,
        accessor: 'course_name',
        type: 'text'
      })
    }

    columns.push(
      {
        Header: createdAtHeaderLabel(billableType),
        accessor: 'created_at',
        type: 'text',
        Cell: ({ value }) => moment(value).format('DD MMM YYYY h:mm A'),
        getTitle: noop
      },
      {
        Header: <Trans>Status</Trans>,
        Cell: StatusCell,
        id: 'status-col',
        disableSortBy: true
      }
    )

    if (hasBillableChangePermission) {
      columns.push({
        Header: '',
        id: 'more-options-col',
        Cell: ({ row, data }) => {
          const event = data[row.index]

          return (
            <EventActionsCell
              contractId={contractId}
              eventGroupId={eventGroupId}
              event={event}
            />
          )
        },
        disableSortBy: true,
        width: 60
      })
    }

    return columns
  }, [billableType, contractId, eventGroupId, hasBillableChangePermission])

  return (
    <AutoloadDataTable
      fetchURL={`/billing/api/event_groups/${eventGroupId}/events`}
      fetchParams={{
        event_group_id: eventGroupId
      }}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      fetchResponseParser={paginatedResponseParser}
      columns={columns}
      emptyMessage={<Trans>No data</Trans>}
    />
  )
}

EventGroupEventsTable.propTypes = {
  billableType: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  eventGroupId: PropTypes.string.isRequired
}

function EngagementTypeCell({ value: type, data, row }) {
  const { t } = useTranslation()

  const event = data[row.index]

  switch (type) {
    case 'applic_engagement':
      return t('{{engagement}} application', {
        engagement: capitalize(event.engagement)
      })
    case 'campaign_engagement':
      return t('Campaign email {{engagementKind}}', {
        engagementKind: event.engagement_kind
      })
    case 'event_engagement':
      return t('Responded to Event {{eventName}}', {
        eventName: event.event_name
      })
    case 'form_engagement':
      return t('Submitted form {{formName}}', {
        formName: event.form_name
      })
    case 'profile_engagement':
      return t('Activated')
    case 'story_engagement':
      return event.story_descriptor
    default:
      return t('Unknown')
  }
}

function createdAtHeaderLabel(billableType) {
  if (billableType === 'applic') return <Trans>Submitted on</Trans>
  if (billableType === 'engagement') return <Trans>Engaged on</Trans>
  if (billableType === 'registration') return <Trans>Registered on</Trans>
  return <Trans>Created on</Trans>
}
