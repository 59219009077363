import React from 'react'
import { Route, Switch } from 'react-router'

import ContractPage from 'apps/Billing/pages/ContractPage'
import ContractSettingsPage from 'apps/Billing/pages/ContractSettingsPage'
import ContractsTablePage from 'apps/Billing/pages/ContractsTablePage'

export const HOME_ROUTE = '/billing'
export const CONTRACTS_TABLE_ROUTE = HOME_ROUTE
export const CONTRACT_EVENTS_ROUTE = '/billing/:contractId/events'
export const CONTRACT_SETTINGS_ROUTE = '/billing/:contractId/settings'

export default function BillingAppRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={CONTRACTS_TABLE_ROUTE}
        component={ContractsTablePage}
      />

      <Route
        exact
        path={CONTRACT_SETTINGS_ROUTE}
        component={ContractSettingsPage}
      />

      <Route path={CONTRACT_EVENTS_ROUTE} component={ContractPage} />
    </Switch>
  )
}
