import { useEffect, useRef } from 'react'

import Pusher from 'pusher-js'

import { useSettings } from '@fullfabric/authorization-officer'

import { useAppData } from 'shared/contexts/AppData'

let pusherInstance

export default function usePusher({
  channelName,
  onMessage,
  binds = [],
  opts = {}
}) {
  const settings = useSettings()
  const { _handle: handle } = useAppData()
  const channelRef = useRef({})

  const config = settings.hash.integrations.pusher

  pusherInstance ||= new Pusher(config.key, {
    cluster: config.cluster,
    authEndpoint: '/api/pusher/auth',
    encrypted: config.encrypted
  })

  // Use Pusher to subscribe to the given channels as soon as this hook is
  // used. Then, unsubscribe once the component is unmounted.
  useEffect(() => {
    const fullName = fullChannelName(channelName, handle, opts)
    const channel = pusherInstance.subscribe(fullName)

    // Bind all events
    binds.forEach((bind) => {
      channel.bind(bind, (data) => onMessage({ bind, data }))
    })

    channelRef.current = channel

    return () => channel.unsubscribe()
  }, [channelName, handle, opts, binds, onMessage])

  return channelRef.current
}

function fullChannelName(channelName, handle, opts) {
  if (opts.tenant) channelName = `${handle}-${channelName}`
  if (opts.private) channelName = `private-${channelName}`
  return channelName
}
