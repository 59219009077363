import React, { useMemo } from 'react'

import { hsvaToHexa, rgbStringToHsva, validHex } from '@uiw/color-convert'
import { ColorPicker } from '@fullfabric/alma-mater'

import { useTenantColors } from 'apps/EmailTemplates/hooks/useTenantColors'

import styles from './styles.module.scss'

// Utility function to always get hexadecimal with alpha from RGB/RGBA
const getHexFromRgb = (color) => {
  if (validHex(color)) {
    return color
  }
  return hsvaToHexa(rgbStringToHsva(color))
}

export const FontColor = ({ editor }) => {
  const value = editor.getAttributes('textStyle').color || '#000000'
  const colors = useTenantColors()

  const hex = useMemo(() => getHexFromRgb(value), [value])

  return (
    <ColorPicker
      id='font-color'
      className={styles.container}
      onChange={(value) => editor.chain().focus().setColor(value).run()}
      value={hex || ''}
      disableClear
      small
      defaultColors={colors}
    />
  )
}
