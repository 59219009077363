import { utils } from '@fullfabric/public-api'

export default async function resetPassword(token, data) {
  const response = await fetch(`/api/public/accounts/password_update`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ...data,
      token,
      password_confirmation: data.password
    })
  })

  return await utils.checkResponse(response)
}
