import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as tus from 'tus-js-client'

import { Button, Text } from '@fullfabric/alma-mater'

import { useAppData } from 'shared/contexts/AppData'

import MAX_FILE_CHUNK_MB from 'apps/EmailTemplates/constants/maxFileChunk'
import AttachmentFile from './AttachmentFile'
import EmptyState from './EmptyState'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function Attachments({ attachments, templateId }) {
  const { t } = useTranslation()
  const { _handle } = useAppData()
  const [internalAttachments, setAttachments] = useState(attachments)
  const [erroredFiles, setErroredFiles] = useState([])
  const [uploadingFiles, setUploadingFiles] = useState([])

  const onFileChange = useCallback(
    (e) => {
      const file = e.nativeEvent.target.files[0]
      const upload = new tus.Upload(file, {
        endpoint: `/api/email_templates/${templateId}/attachments/resumable`,
        chunkSize: MAX_FILE_CHUNK_MB,
        metadata: {
          filename: file.name,
          filetype: file.type,
          channelName: `${_handle}-tus-uploads-attachments`,
          emailTemplateId: templateId
        },
        onShouldRetry: (error) => {
          if (error.originalResponse.getStatus() === 406) {
            return false
          }
        },
        onAfterResponse: (_, res) => {
          if (res.getStatus() === 201 && res.getBody()) {
            setUploadingFiles((uploads) =>
              uploads.filter((internalUpload) => internalUpload.file !== file)
            )
            setAttachments((attachments) => [
              ...attachments,
              JSON.parse(res.getBody())
            ])
          }
        },
        onError: (error) => {
          setErroredFiles((files) => [
            ...files,
            { file, error: error.originalResponse.getBody() }
          ])
        }
      })
      setUploadingFiles((uploadingFiles) => [...uploadingFiles, upload])
      upload.start()
      e.nativeEvent.target.value = null
    },
    [_handle, templateId]
  )

  const onDelete = useCallback(
    (id) => {
      if (window.confirm('Are you sure?')) {
        fetch(`/api/email_templates/${templateId}/attachments/${id}`, {
          method: 'DELETE'
        })
          .then(() =>
            setAttachments((attachments) =>
              attachments.filter((attachment) => attachment.id !== id)
            )
          )
          .catch(console.error)
      }
    },
    [templateId]
  )

  const rows = internalAttachments.map((attachment, index) => (
    <AttachmentFile
      key={index}
      name={attachment.name}
      id={attachment.id}
      path={attachment.path}
      templateId={templateId}
      onDelete={onDelete}
    />
  ))

  if (!uploadingFiles.length && rows.length === 0) {
    rows.push(<EmptyState key='empty-state' />)
  }

  uploadingFiles.forEach((upload) => {
    const errorInfo = erroredFiles.find(({ file }) => file === upload.file)
    rows.push(
      <AttachmentFile
        key={rows.length}
        name={upload.file.name}
        uploading={!errorInfo}
        error={errorInfo?.error}
        onErrorDismiss={() => {
          setErroredFiles((errorFiles) =>
            errorFiles.filter(({ file }) => file !== upload)
          )
          setUploadingFiles((uploads) =>
            uploads.filter((internalUpload) => internalUpload !== upload)
          )
        }}
      />
    )
  })

  return (
    <>
      <div
        className={classNames(
          styles.content,
          styles.withBorder,
          'attachments-container'
        )}
      >
        <div className={styles.uploadContainer}>
          <div className={styles.leftInfo}>
            <Text type='h5' fontColor='text-base-darkest'>
              {t('Upload file')}
            </Text>
          </div>
          <Button size='small' className={styles.uploadButton}>
            <input
              className={styles.uploadFile}
              name='file'
              type='file'
              onChange={onFileChange}
            />
            {t('Attach file')}
          </Button>
        </div>
      </div>
      <div className={classNames(styles.content, 'files-attached')}>
        <div className={styles.filesListTitle}>
          <Text
            type='h5'
            fontColor='text-base-darkest'
            className={styles.filesAttachedTitle}
          >
            {t('Files attached')}
          </Text>
          <Text type='f6' fontColor='text-base-darker'>
            {t('Total attachment size must not exceed 20MB')}
          </Text>
        </div>
        <div className={styles.filesList}>{rows}</div>
      </div>
    </>
  )
}
