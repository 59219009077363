import { useMutation, useQueryClient } from 'react-query'

import completeTask from 'apps/Inbox/api/completeTask'
import updateCard from 'apps/Inbox/api/updateCard'
import {
  useCloseScheduledToComplete,
  useSetScheduledToComplete,
  useUndoScheduledToComplete
} from 'apps/Inbox/contexts/scheduledToComplete'
import { useCardFilters } from '../contexts/CardFilters'

export default function useHandleComplete() {
  const queryClient = useQueryClient()
  const { cardStatus, cardType } = useCardFilters()

  const setScheduledToComplete = useSetScheduledToComplete()
  const undoScheduledToComplete = useUndoScheduledToComplete()
  const closeScheduledToComplete = useCloseScheduledToComplete()

  const { mutate: completeTaskMutation } = useMutation(
    async ({ card }) => {
      const { entity: task } = card

      const updatedTask = await completeTask(task.id)
      const updatedCard = await updateCard({ ...card, status: 'archived' })

      return {
        updates: { updatedTask, updatedCard },
        card,
        cardStatus,
        cardType
      }
    },
    {
      onMutate: async ({ card }) => {
        setTimeout(() => closeScheduledToComplete({ card }), 5000)
        setScheduledToComplete({ card, index: undefined, pending: true })

        const previousCards =
          queryClient.getQueryData(['cards', cardStatus, cardType]) || []

        const newCards = previousCards?.pages.map((page) => {
          const data = page.data.filter((val) => val.id !== card.id)
          return { ...page, data }
        })

        queryClient.setQueriesData(['cards', cardStatus, cardType], (data) => ({
          pages: newCards,
          pageParams: data.pageParams
        }))

        return { previousCards, cardStatus, cardType, card }
      },
      onError: (_err, _data, context) => {
        const { cardType, card } = context
        undoScheduledToComplete({ card })
        queryClient.invalidateQueries(['cards', cardStatus, cardType])
      }
    }
  )

  return completeTaskMutation
}
