import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { AddIcon, PlainButton, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useDisplayFeedbackMessage } from 'apps/ContentPages/components/feeback-message-provider'
import amountToPrice from 'apps/ContentPages/services/payments/amount-to-price'
import ExternalPayerRequestModal from '../external-payer-request-modal'
import NewPlanFeeModal from '../new-plan-fee-modal'

import classNames from 'classnames'
import styles from './fees-editor/styles.module.scss'

export default function FeesEditor({ paymentPlan, fees }) {
  const queryClient = useQueryClient()
  const [newPlanFeeModalOpen, setNewPlanFeeModalOpen] = useState(false)
  const [externalPayerRequestModalOpen, setExternalPayerRequestModalOpen] =
    useState(false)
  const [externalPayerPayable, setExternalPayerPayable] = useState(null)

  const displayFeedbackMessage = useDisplayFeedbackMessage()
  const user = useCurrentUser()
  const { t } = useTranslation()

  const feesAmountSum = fees.reduce((sum, item) => sum + (item.amount || 0), 0)
  const planContextPriceAmount = paymentPlan.context_price_amount * 100
  const planContextPriceCurrency = paymentPlan.context_price_currency
  const diff = planContextPriceAmount - feesAmountSum

  const validFeesAmountSum = feesAmountSum === planContextPriceAmount

  const handleCallback = async ({ name, data }) => {
    if (name === 'new_fee') {
      displayFeedbackMessage(t('Fee was added successfully'))
      setNewPlanFeeModalOpen(false)
    } else if (name === 'new_fee_with_external_payer') {
      setNewPlanFeeModalOpen(false)
      setExternalPayerPayable(data)
      setExternalPayerRequestModalOpen(true)
    } else if (name === 'set_external_payer') {
      setExternalPayerRequestModalOpen(false)
      displayFeedbackMessage(t('Fee was added successfully'))
    }

    queryClient.invalidateQueries(['get-payment-plan', user.id])
    queryClient.invalidateQueries(['get-payment-plans', user.id])
  }

  return (
    <>
      {!fees.length && (
        <div className={styles.noFeesContainer}>
          <Text type='h4' fontColor='text-base-darker'>
            <Trans>No fees to display here</Trans>
          </Text>
          <Text type='f6' fontColor='text-base-darker'>
            <Trans>
              Define an amount for each fee until it reaches product's total
              price
            </Trans>
          </Text>
        </div>
      )}
      <div className={styles.addFeeBtnContainer}>
        <PlainButton
          primary
          className={styles.addFeeBtn}
          onClick={() => setNewPlanFeeModalOpen(true)}
        >
          <PlainButton.LeftIcon>
            <AddIcon />
          </PlainButton.LeftIcon>
          Add fee
        </PlainButton>
      </div>
      <div
        className={classNames([
          styles.planStatusContainer,
          validFeesAmountSum ? styles.valid : styles.invalid
        ])}
      >
        <div>
          <Text type='h4' fontColor='text-base-darkest'>
            <Trans>Total</Trans>
          </Text>
          <Text
            type='f5'
            fontColor={validFeesAmountSum ? 'text-success' : 'text-alert'}
          >
            <Trans>Status</Trans>
          </Text>
        </div>
        <div>
          <Text type='h4' fontColor='text-base-darkest'>
            {planContextPriceCurrency && planContextPriceAmount
              ? amountToPrice(
                  planContextPriceCurrency,
                  planContextPriceAmount || 0
                )
              : '-'}
          </Text>
          <Text
            type='f5'
            fontColor={validFeesAmountSum ? 'text-success' : 'text-alert'}
          >
            {validFeesAmountSum ? (
              <Trans>All cleared</Trans>
            ) : (
              <>
                {diff > 0 ? <Trans>Missing</Trans> : <Trans>Over</Trans>}{' '}
                {amountToPrice(planContextPriceCurrency, Math.abs(diff))}
              </>
            )}
          </Text>
        </div>
      </div>
      {newPlanFeeModalOpen && (
        <NewPlanFeeModal
          isOpen={newPlanFeeModalOpen}
          onClose={() => setNewPlanFeeModalOpen(false)}
          onAction={(action) => handleCallback(action)}
          paymentPlan={paymentPlan}
        />
      )}
      {externalPayerRequestModalOpen && (
        <ExternalPayerRequestModal
          isOpen={externalPayerRequestModalOpen}
          onClose={() => {
            setExternalPayerRequestModalOpen(false)
          }}
          onAction={() => handleCallback({ name: 'set_external_payer' })}
          payable={externalPayerPayable}
          requiresApproval={true}
        />
      )}
    </>
  )
}
