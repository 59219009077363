import React from 'react'
import { Trans } from 'react-i18next'

import PropTypes from 'prop-types'

import { Button, ModalButtons } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function Footer({
  ready,
  exporting,
  showExport,
  onClose,
  onExport,
  onReset
}) {
  return (
    <ModalButtons>
      {!showExport && (
        <Button className={styles.resetButton} onClick={onReset}>
          <Trans>Reset</Trans>
        </Button>
      )}

      <Button onClick={onClose}>
        <Trans>Close</Trans>
      </Button>

      {showExport && (
        <Button
          primary
          disabled={!ready}
          loading={exporting}
          onClick={onExport}
        >
          <Trans>Export</Trans>
        </Button>
      )}
    </ModalButtons>
  )
}

Footer.propTypes = {
  ready: PropTypes.bool.isRequired,
  showExport: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}
