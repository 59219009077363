const getFieldNames = (schema, prefix) => {
  return schema?.sections
    ?.map((section) => section.fields)
    .reduce((acc, cur) => [...acc, ...cur], [])
    .map((field) => [prefix, field.name].filter((x) => x).join('.'))
}

const getFieldFromName = (schema, fieldName) => {
  return schema?.sections
    ?.map((section) => section.fields)
    .reduce((acc, cur) => [...acc, ...cur], [])
    .find((field) => field.name === fieldName)
}

const getFieldLabelFromName = (schema, fieldName) => {
  const field = getFieldFromName(schema, fieldName)
  return field?.locals?.find((local) => local.locale === 'en-GB')?.label
}

const getFieldSectionId = (fieldId, schema) => {
  const section = schema.sections.find((section) =>
    section.fields.some((f) => f.id === fieldId)
  )

  return section?.id
}

const schemaFields = (schema) => {
  return schema?.sections
    ?.map((section) => section.fields)
    .reduce((acc, cur) => [...acc, ...cur], [])
}

const mapSchemaSectionsWithFields = (schema, fieldsToExclude = []) => {
  return schema?.sections.map((section, index) => {
    const filteredFields = section.fields.filter(
      (field) => !fieldsToExclude.includes(field.name)
    )

    const fields = filteredFields.map((field) => {
      const local = field.locals?.find((local) => local.locale === 'en-GB')
      const label = local?.label || field.name

      if (field.type === 'Address') {
        return [
          [label, field.name],
          [`${label} Line 1`, `${field.name}.line_1`],
          [`${label} Line 2`, `${field.name}.line_2`],
          [`${label} Line 3`, `${field.name}.line_3`],
          [`${label} City`, `${field.name}.city`],
          [`${label} State`, `${field.name}.state`],
          [`${label} Post code`, `${field.name}.post_code`],
          [`${label} Country`, `${field.name}.country`]
        ]
      }

      return [[label, field.name]]
    })

    const sectionLocal = section.locals?.find(
      (local) => local.locale === 'en-GB'
    )
    const sectionLabel = sectionLocal?.label || `Section ${index + 1}`

    return [sectionLabel, fields.flat(), true]
  })
}

const mapSchemaSectionsToOptions = (schema, fieldsToExclude = []) => {
  return mapSchemaSectionsWithFields(schema, fieldsToExclude).map(
    ([label, fields]) => ({
      label,
      options: fields.map(([label, value]) => ({
        value,
        label
      }))
    })
  )
}

export {
  getFieldNames,
  getFieldLabelFromName,
  getFieldFromName,
  getFieldSectionId,
  schemaFields,
  mapSchemaSectionsWithFields,
  mapSchemaSectionsToOptions
}
