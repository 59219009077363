import React from 'react'

import { DropdownInput } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const fontSizes = [
  '10px',
  '11px',
  '12px',
  '13px',
  '14px',
  '15px',
  '16px',
  '20px',
  '24px',
  '32px',
  '36px',
  '40px',
  '48px'
].map((value) => ({ value, label: value }))

export const FontSize = ({ editor }) => {
  const value = editor.getAttributes('textStyle').fontSize || '13px'
  return (
    <DropdownInput
      id='font-size'
      className={styles.container}
      onChange={(value) =>
        editor.chain().focus().setFontSize(value).setLineHeight('130%').run()
      }
      value={value || ''}
      options={fontSizes}
      small
    />
  )
}
