const FIELDS = ['min_mandatory_courses', 'max_mandatory_courses']

const validateNumber = (data, field) =>
  data[field] != null && isNaN(data[field]) ? { [field]: 'notANumber' } : {}

const validateZero = (data, field) =>
  data[field] != null && parseInt(data[field]) <= 0 ? { [field]: 'zero' } : {}

const validateMinimumLessThanMaximum = (data) => {
  return data.field === 'min_mandatory_courses' &&
    data.min_mandatory_courses != null &&
    data.max_mandatory_courses != null &&
    parseInt(data.min_mandatory_courses) > parseInt(data.max_mandatory_courses)
    ? { min_mandatory_courses: 'minMustBeLessThanMax' }
    : {}
}

const validateMaximumGreaterThanMinimum = (data) => {
  return data.field === 'max_mandatory_courses' &&
    data.min_mandatory_courses != null &&
    data.max_mandatory_courses != null &&
    parseInt(data.min_mandatory_courses) > parseInt(data.max_mandatory_courses)
    ? { max_mandatory_courses: 'maxMustBeGreaterThanMin' }
    : {}
}

const validateGroupData = (data) => {
  return {
    ...validateMinimumLessThanMaximum(data),
    ...validateMaximumGreaterThanMinimum(data),
    ...FIELDS.reduce(
      (err, field) => ({
        ...err,
        ...validateZero(data, field)
      }),
      {}
    ),
    ...FIELDS.reduce(
      (err, field) => ({
        ...err,
        ...validateNumber(data, field)
      }),
      {}
    )
  }
}

export default validateGroupData
