import React from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown, FormatIcon, PlainIconButton } from '@fullfabric/alma-mater'

export const Format = ({ editor }) => {
  const { t } = useTranslation()

  return (
    <Dropdown disablePortal>
      <Dropdown.Toggle>
        <PlainIconButton>
          <FormatIcon />
        </PlainIconButton>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Menu.Option
          onClick={() => editor.chain().focus().setParagraph().run()}
        >
          {t('Default')}
        </Dropdown.Menu.Option>
        <Dropdown.Menu.Option
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
        >
          {t('Quote')}
        </Dropdown.Menu.Option>
        <Dropdown.Menu.Option
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          {t('Heading 1')}
        </Dropdown.Menu.Option>
        <Dropdown.Menu.Option
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          {t('Heading 2')}
        </Dropdown.Menu.Option>
        <Dropdown.Menu.Option
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          {t('Heading 3')}
        </Dropdown.Menu.Option>
        <Dropdown.Menu.Option
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
        >
          {t('Heading 4')}
        </Dropdown.Menu.Option>
      </Dropdown.Menu>
    </Dropdown>
  )
}
