import React, { useState } from 'react'
import { Trans } from 'react-i18next'

import { Link, SuccessIcon, Text } from '@fullfabric/alma-mater'

import sendVerificationEmail from 'apps/ContentPages/api/send-verification-email'
import useIsMobileLayout from 'apps/ContentPages/hooks/use-is-mobile-layout'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function ResendVerificationEmail() {
  const isMobileLayout = useIsMobileLayout()
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  return sent && !error ? (
    <div className={styles.resendStateContainer}>
      <span
        className={classNames([styles.icon, isMobileLayout && styles.mobile])}
      >
        <SuccessIcon color='text-success' />
      </span>
      <Text type='h5' fontColor='text-success' tag='span'>
        <Trans>Email sent</Trans>
      </Text>
    </div>
  ) : (
    <div className={styles.resendStateContainer}>
      {sent && error && (
        <Text type='h5' fontColor='text-alert' tag='span' marginRight='tiny'>
          <Trans>Email failed!</Trans>
        </Text>
      )}
      <Link
        onClick={() => resendVerificationEmail(setSent, setError)}
        className={styles.resendButton}
      >
        <Text type='h5' fontColor='text-primary' tag='span'>
          <Trans>Resend verification email</Trans>
        </Text>
      </Link>
    </div>
  )
}

async function resendVerificationEmail(setComplete, setError) {
  try {
    await sendVerificationEmail()
    setComplete(true)
  } catch (error) {
    console.error('Error sending verification email', error)

    setComplete(true)
    setError(true)
  }
}
