import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  AnnotatedLayout,
  ArrowLeftIcon,
  PageHead
} from '@fullfabric/alma-mater'

import SuccessPopupMessage from 'shared/components/SuccessPopupMessage'

import getContract from 'apps/Billing/api/getContract'
import getContractName from 'apps/Billing/utils/getContractName'
import ContractBasicData from './ContractBasicData'
import ContractBasicEditForm from './ContractBasicEditForm'

const ContractSettingsPage = ({ contractId }) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const [feedbackMessageIsDisplayed, setFeedbackMessageIsDisplayed] =
    useState(false)
  const { data: contract, isLoading } = useQuery(
    ['getContract', contractId],
    () => getContract(contractId)
  )

  return (
    <AnnotatedLayout
      pageHead={
        <PageHead
          title={<Trans>Settings</Trans>}
          breadcrumbs={[
            {
              label: isLoading ? (
                <Trans>Back</Trans>
              ) : (
                <Trans>Back to {{ to: getContractName(contract) }}</Trans>
              ),
              icon: ArrowLeftIcon,
              to: `/billing/${contractId}/events`
            }
          ]}
        />
      }
    >
      <AnnotatedLayout.Section
        title={<Trans>Basic</Trans>}
        description={<Trans>The contract's basic information</Trans>}
      >
        {isEditing ? (
          <ContractBasicEditForm
            contract={contract}
            onSubmittedChanges={() => {
              setFeedbackMessageIsDisplayed(true)
              setIsEditing(false)
            }}
          />
        ) : (
          <ContractBasicData
            isLoading={isLoading}
            contract={contract}
            onEdit={() => setIsEditing(true)}
          />
        )}
      </AnnotatedLayout.Section>

      <SuccessPopupMessage
        isDisplayed={feedbackMessageIsDisplayed}
        onAfterTimeout={() => setFeedbackMessageIsDisplayed(false)}
        message={t('Changes saved')}
      />
    </AnnotatedLayout>
  )
}

export default ContractSettingsPage
